import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Label from "../../styledComponent/Inputs/Label";
import InputField from "../../styledComponent/Inputs/InputField";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import DateField from "../../styledComponent/Inputs/DateField";
import CheckField from "../../styledComponent/Inputs/CheckField";
export default function PanVerify() {

  const [activeScreen, setActiveScreen] = useState(1);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const { theme, strings } = useSelector((s) => s.platformSetting);

  const handleChange = (index, e) => {
    if (e.target.value.length > 1) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    if (e.target.value !== "" && index < otp.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    setOtp(newOtp);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    if (pastedData.length === otp.length) {
      const newOtp = pastedData.split("").slice(0, otp.length);
      setOtp(newOtp);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };


  return (
    <Root theme={theme} className="min_container">
      <h4>{strings.signUp.verify_your_pan} ?</h4>
      <div>
        <Label label={strings.signUp.pan_card_number} />
        <InputField
          placeholder={strings.placeholder.enter_10_digit_pan}
          error={""}
          value={""}
          handleChange={() => {}}
        />
        <Label label={strings.signUp.pan_card_number} />
        <DateField
          placeholder={strings.income.date_of_receipt}
          error={strings.message.date_format}
          value={""}
          handleChange={() => {}}
        />
      </div>
      {activeScreen == 1 ? (
        <>
          <h4 className="my-4">
            {strings.signUp.choose_method_for_verifying_otp}{" "}
          </h4>
          <CheckField
            error={""}
            value={""}
            handleChange={() => {}}
            options={[
              strings.signUp.it_department_registered,
              strings.signUp.aadhaar_registered_mobile_number,
            ]}
          />
          <PrimaryButton
            title={strings.button.next}
            handleClick={() => {setActiveScreen(2)}}
            addClass={"mt-10"}
          />
        </>
      ) : activeScreen == 2 ? (
        <>
       <div className="text-center">
       <Label label={strings.signUp.enter_otp_to_verify} />
        {otp.map((value, index) => (
            <input
              key={index}
              ref={inputRefs[index]}
              type="number"
              maxLength={1}
              className="w-12 h-12 mx-1 text-2xl border border-gray-300 text-center focus:outline-none rounded-xl font-medium otp_input"
              value={value}
              onChange={(e) => handleChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={handlePaste}
            />
          ))}
       </div>
          <h6 className="text-center mt-4 font-regular">{strings.signUp.if_dont_receive_code} ? <button style={{ color: theme.primary_bg }}>{strings.button.resend}</button></h6>


        <PrimaryButton
            title={strings.signUp.verify_otp}
            handleClick={() => {}}
            addClass={"mt-10"}
          />
        </>
      ) : (
        ""
      )}
    </Root>
  );
}
const Root = styled.div`
  margin-top: 30px;
  border-radius: 10px;
  background-color: ${(p) => p.theme.secandary_bg};
`;
