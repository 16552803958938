import React from "react";
import styled from "styled-components";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";
import Label from "../styledComponent/Inputs/Label";
import { useSelector } from "react-redux";
import CheckField from "../styledComponent/Inputs/CheckField";
export default function Questions() {
  const { strings } = useSelector((p) => p.platformSetting);

  const dummyOptions = [
    {
      id: 1,
      question:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      options: ["Yes", "No"],
    },
    {
      id: 2,
      question:
        "The point of using Lorem Ipsum is that it has a more-or-less normal.",
      options: ["Option 1", "Option 2", "Option 3", "Option 4"],
    },
    {
      id: 3,
      question:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
      options: ["Yes", "No"],
    },
    {
      id: 4,
      question:
        "The point of using Lorem Ipsum is that it has a more-or-less normal.",
      options: ["Option 1", "Option 2", "Option 3", "Option 4","Option 1","Option 1","Option 1","Option 1"],
    },
    {
      id: 5,
      question:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
      options: ["Yes", "No"],
    },
  ];
  return (
    <Root>
      {dummyOptions.map((item) => 
        <StyleFormBox>
          <Label
            label={item.id + ' . ' + item.question}
          />
          <CheckField
            error={""}
            value={""}
            handleChange={() => {}}
            options={item.options}
          />
        </StyleFormBox>
        )}
    </Root>
  );
}
const Root = styled.section`
  max-width: 800px;
  margin: auto;
  padding: 10px;
`;
