import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import SelectOption from "../../styledComponent/Inputs/SelectOption";
import Label from "../../styledComponent/Inputs/Label";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import InputField from "../../styledComponent/Inputs/InputField";
import PhoneField from "../../styledComponent/Inputs/PhoneField";
import DateField from "../../styledComponent/Inputs/DateField";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import SecandaryButton from "../../styledComponent/Button/SecandaryButton";
import ConfirmationBox from "../DialogBox/ConfirmationBox";
import { FaCheck } from "react-icons/fa";

export default function AddDocuments() {
  const [sendOtp, setSendOtp] = useState(false);
  const [verifyBox , setVerifyBox] = useState(false)
  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const getOtp = () => {
    setSendOtp(true);
    if(otp.every(value => value != '')){
      setVerifyBox(true)
    }

  };
  const handleChange = (index, e) => {
    if (e.target.value.length > 1) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    if (e.target.value !== "" && index < otp.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    setOtp(newOtp);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    if (pastedData.length === otp.length) {
      const newOtp = pastedData.split("").slice(0, otp.length);
      setOtp(newOtp);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };
  return (
    <Root theme={theme}>
      <StyleFormBox>
        <Label label={strings.document.choose_your_document} />
        <SelectOption
          options={[
            strings.document.aadhaar_card,
            strings.document.aadhaar_card,
          ]}
          error={""}
          value={""}
          handleChange={() => {}}
        />
      </StyleFormBox>
      <StyleFormBox>
        <h4>{strings.document.verify_aadhaar_card}</h4>

        <Label label={strings.document.aadhaar_card_number} />

        <InputField
          placeholder={strings.document.enter_12_digit_aadhaar_number}
          error={""}
          value={""}
          handleChange={() => {}}
          type={"number"}
        />

        <Label label={strings.label.phone_number} />

        <PhoneField
          placeholder={strings.placeholder.enter_10_digit}
          error={strings.document.enter_aadhaar_registered_number}
          value={""}
          handleChange={() => {}}
        />

        <Label label={strings.label.date_of_birth} />

        <DateField
          placeholder={strings.label.date_of_birth}
          error={""}
          value={""}
          handleChange={() => {}}
        />
      </StyleFormBox>

      {sendOtp && (
        <StyleFormBox>
          <Label label={strings.signUp.enter_otp_to_verify} />
          {otp.map((value, index) => (
            <input
              key={index}
              ref={inputRefs[index]}
              type="number"
              maxLength={1}
              className="w-12 h-12 mx-1 text-2xl border border-gray-300 text-center focus:outline-none rounded-xl font-medium otp_input"
              value={value}
              onChange={(e) => handleChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={handlePaste}
            />
          ))}
        </StyleFormBox>
      )}
      <PrimaryButton
        title={strings.button.continue}
        handleClick={() => {
          getOtp();
        }}
        addClass={"mt-5"}
      />

      {/* Dialog Box  */}
      <ConfirmationBox
        open={verifyBox}
        handleClose={() => {
          setVerifyBox(false)
        }}
      >
        <div className="text-center">
          <div className="icon text-3xl bg-green-500 w-auto mx-auto my-4 mb-8 rounded-full p-4 inline-block" style={{color:theme.secandary_txt}}>
            <FaCheck />
          </div>
          <h4>{strings.profile.verified_document}</h4>
          <p>{strings.profile.successfully_verified_document}</p>
          <div className="flex items-center gap-4 mt-4">
            <SecandaryButton
              title={strings.profile.add_document}
              handleClick={() => {}}
            />
            <PrimaryButton title={strings.button.continue} />
          </div>
        </div>
      </ConfirmationBox>
      {/* Dialog Box  */}
    </Root>
  );
}
const Root = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: ${(p) => p.theme.secandary_bg};
  .otp_input {
    color: ${(p) => p.theme.primary_txt};
    background-color: transparent;
  }
`;
