import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import { useSelector } from "react-redux";
import { addOpacity } from "../../utils/helpers";
import ProgressBar from "../Form/ProgressBar";
export default function ItrStatus({ data }) {
  const [statusData, setStatusData] = useState({});

  const { strings, theme } = useSelector((s) => s.platformSetting);

  useEffect(() => {
    if (data) {
      setStatusData(data);
    }
  }, [data]);
  return (
    <Root theme={theme}>
      <div className="flex items-center justify-between">
        <h4>{statusData.title}</h4>
        <span className={statusData.status}>{statusData.status}</span>
      </div>
      <div className="flex items-center justify-between">
        <div>
        <div className="progress_bar">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
          <p>2 steps completed</p>
        </div>
        <button className="action_btn">{strings.button.continue}</button>
      </div>
    </Root>
  );
}
const Root = styled.div`
  background-color: ${(p) => addOpacity(p.theme.tertiary_bg, 0.5)};
  padding: 10px;
  border-radius: 10px;
  span.In.Progress {
    color: #e77c19;
  }
  span.Pending {
    color: #e7d219;
  }
  span.Successful {
    color: #29e719;
  }
  .action_btn {
    background-color: ${(p) => p.theme.primary_bg};
    color: ${(p) => p.theme.secandary_txt};
    border-radius: 4px;
    padding: 4px 10px;
    cursor: pointer;
    font-weight: 500;
  }
  .progress_bar{
    display:flex;
    gap:18px;
    margin: 10px 0px;
    span{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #fff;
    }
  }
`;
