import React, { useState } from "react";
import OtpScreen from "../component/LogIn/OtpScreen";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { addOpacity } from "../utils/helpers";
import CreateUser from "../component/LogIn/CreateUser";
import PanVerify from "../component/LogIn/PanVerify";
export default function RegisterUser() {
  const [activeScreen, setActiveScreen] = useState("create");
  const { strings, theme } = useSelector((s) => s.platformSetting);
  console.log('activeScreen',activeScreen)
  return (
    <Root theme={theme}>
      {activeScreen == "create" ? (
        <CreateUser
          handleClick={() => {
            setActiveScreen("otp");
          }}
        />
      ) : activeScreen == "otp" ? (
        <OtpScreen
          handleClick={() => {
            setActiveScreen("pan");
          }}
        />
      ) : activeScreen == "pan" ? (
        <PanVerify />
      ) : (
        ""
      )}
    </Root>
  );
}
const Root = styled.section`
  max-width: 800px;
  margin: auto;
  
`;
