import React, { useState } from "react";
import CurrencyField from "../../../styledComponent/Inputs/CurrencyField";
import AccordianBox from "../../../styledComponent/Form/AccordianBox";
import { useSelector } from "react-redux";
import Label from "../../../styledComponent/Inputs/Label";
import { CiMenuKebab } from "react-icons/ci";
import SecandaryButton from "../../../styledComponent/Button/SecandaryButton";
import PrimaryButton from "../../../styledComponent/Button/PrimaryButton";
import styled from "styled-components";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import EmployerTdsDetails from "./EmployerTdsDetails";
import SalaryBreakup from "./SalaryBreakup";
import ExemptAllowances from "./ExemptAllowances";
import OtherSourceIncome from "./OtherSourceIncome";
import FormDialogBox from "../../DialogBox/FormDialogBox";
import HousePropertyIncome from "./HousePropertyIncome";
import AddActionButton from "../../../styledComponent/Button/AddActionButton";
import CaptialGainIncome from "./DialogBox/CaptialGainIncome";
import CryptoIncome from "./DialogBox/CryptoIncome";
export default function GrossIncome({ handleNext }) {
  const [openIncomeForm, setOpenIncomeForm] = useState(false);
  const [captialIncomeBox, setCaptialIncomeBox] = useState(false);
  const [houseIncomeForm, setHouseIncomeForm] = useState(false);
  const [addCryptoIncome, setAddCryptoIncome] = useState();
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root>
      <AccordianBox
        title={strings.tax.salary_income}
        description={strings.tax.you_can_add_salary_income}
      >
        <EmployerTdsDetails />
        <SalaryBreakup />
        <ExemptAllowances />
        <StyleFormBox>
          <div className="flex-1 flex items-center justify-between">
            <h4 style={{ color: theme.primary_txt }}>
              {strings.label.balance} (1 -2)
            </h4>
            <h4 style={{ color: theme.primary_txt }}>
              {strings.label.rupee} 10,000
            </h4>
          </div>
        </StyleFormBox>
        <AccordianBox title={strings.tax.deductions_and_relief}>
          <div className="flex_input">
            <div>
              <Label label={strings.label.standard_deductions_16ia} />
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
            <div>
              <Label
                label={strings.label.professional_tax_under_section_16iii}
              />
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
          </div>

          <div className="flex_input">
            <div>
              <Label label={strings.label.relief_under_section_89} />
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
            <div>
              <Label
                label={strings.label.income_claimed_for_relief_under_sectio_89a}
              />
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
          </div>
        </AccordianBox>
        <StyleFormBox>
          <div className="flex-1 flex items-center justify-between">
            <h4 style={{ color: theme.primary_txt }}>
              {strings.tax.income_chargeable_under_head_salaries} (1 -2)
            </h4>
            <h4 style={{ color: theme.primary_txt }}>
              {strings.label.rupee} 10,000
            </h4>
          </div>
        </StyleFormBox>
      </AccordianBox>

      <AccordianBox
        title={strings.tax.house_property_income}
        description={strings.tax.add_details_earned_rent_from_property}
      >
        <div className="flex justify-end items-center">
          <h4>House Property 1</h4>
          <h2>34,000</h2>
          <button>
            <CiMenuKebab />
          </button>
        </div>
        <FormDialogBox
          open={houseIncomeForm}
          formTitle={strings.button.add_entry}
          handleClose={() => {
            setHouseIncomeForm(false);
          }}
        >
          <HousePropertyIncome />
        </FormDialogBox>
        <SecandaryButton
          title={strings.button.add_entry}
          handleClick={() => {
            setHouseIncomeForm(true);
          }}
        />
      </AccordianBox>
      <StyleFormBox>
        <div className="flex items-center justify-between">
          <h4>{strings.income.capital_gain_income}</h4>
          <AddActionButton
            title={strings.button.add}
            handleChange={() => setCaptialIncomeBox(true)}
          />
        </div>
        <p>{strings.income.stocks_mutual_funds_land_bonds_jewellery}</p>
      </StyleFormBox>
      <StyleFormBox>
        <div className="flex items-center justify-between">
          <h4>{strings.income.crypto_income}</h4>
          <AddActionButton
            title={strings.button.add}
            handleChange={() => setAddCryptoIncome(true)}
          />
        </div>
        <p>{strings.income.add_income_from_crypto_trades_staking_minting}</p>
      </StyleFormBox>

      <AccordianBox
        title={strings.tax.other_source_income}
        description={
          strings.tax.fixed_deposite_savings_bank_interest_agriculture
        }
      >
        <FormDialogBox
          open={openIncomeForm}
          formTitle={strings.button.add_entry}
          handleClose={() => {
            setOpenIncomeForm(false);
          }}
        >
          <OtherSourceIncome />
        </FormDialogBox>

        <SecandaryButton
          title={strings.button.add_entry}
          handleClick={() => {
            setOpenIncomeForm(true);
          }}
        />
      </AccordianBox>

      <FormDialogBox
        open={captialIncomeBox}
        formTitle={strings.income.capital_gain_income}
        handleClose={() => {
          setCaptialIncomeBox(false);
        }}
      >
        <CaptialGainIncome />
      </FormDialogBox>
      <FormDialogBox
        open={addCryptoIncome}
        formTitle={strings.income.crypto_income}
        handleClose={() => {
          setAddCryptoIncome(false);
        }}
      >
        <CryptoIncome />
      </FormDialogBox>

      {/* 
      <SecandaryButton
        title={strings.button.upload_form_16}
        handleClick={() => {
          // handleNext(strings.tax.tax_saving);
        }}
      />

      <PrimaryButton
        title={strings.button.next}
        handleClick={() => {
          handleNext(strings.tax.tax_saving);
        }}
      /> */}
    </Root>
  );
}
const Root = styled.div`
  .flex_title {
    display: flex;
    justify-content: space-between;
  }
`;
