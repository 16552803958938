
import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { addOpacity } from '../../utils/helpers';
export default function SocialButton({children}) {
    const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>{children}</Root>
  )
}
const Root = styled.div`
    button {
        background: transparent;
        border: 2px solid ${(p) => p.theme.border_clr};
        border-radius: 12px;
        font-size: 32px;
        padding: 16px 20px;
        display:flex;
        cursor: pointer;
    }
`