import React from "react";
import AccordianTab from "../../styledComponent/Form/AccordianTab";
import styled from "styled-components";
import ItrStatus from "./ItrStatus";
export default function TrackStatus() {
  const dummyData = [
   {
    id:1,
    title:'ITR1',
    status:'In Progress',
    stepDone:'2',
   },
   {
    id:2,
    title:'ITR1',
    status:'Pending',
    stepDone:'2',
   },
   {
    id:3,
    title:'ITR1',
    status:'Successful',
    stepDone:'2',
   },
   {
    id:4,
    title:'ITR1',
    status:'In Progress',
    stepDone:'2',
   },
  ]
  return (
    <Root>
      <AccordianTab title={"Track Status"} >
        <div className="grid grid-cols-2 gap-4">
        {dummyData.map((i,ix)=> {
         return( <div key={ix}><ItrStatus data={i}/></div>)
        })}
        </div>
      </AccordianTab>
    </Root>
  );
}
const Root = styled.div``;
