import "./App.css";
import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./page/Home";
import SignIn from "./page/SignIn";
import ITR1 from "./page/ITR1";
import ITR34 from "./page/ITR34";
import { useSelector } from "react-redux";
import WebLayout from "./component/Layout";
import Test from "./page/test";
import RegisterUser from "./page/RegisterUser";
import styled from "styled-components";
import Profile from "./page/Profile";
import PasswordReset from "./page/PasswordReset";
import Questions from "./page/Questions";
import PanVerify from "./component/LogIn/PanVerify";
import IRT1Form from "./component/ITR1/IRT1Form";
import AddDocuments from "./component/Form/AddDocuments";
import TermConditions from "./page/TermConditions";
import AboutUs from "./page/AboutUs";
import ITR34Form from "./component/IRT3-4/ITR34Form";
import TicketsChatBox from "./page/TicketsChatBox";
import Settings from "./page/Settings";
import ContactUs from "./page/ContactUs";
import Faq from "./page/Faq";
import PrivacyPolicy from "./page/PrivacyPolicy";
import Glossary from "./page/Glossary/Glossary";
import GlossaryById from "./page/Glossary/GlossaryById";
import ITR2 from "./page/ITR2";
import IRT2Form from "./component/ITR2/IRT2Form";
import Firm from "./page/Firm";
import HUFForm from "./page/HUFForm";

function App() {
  const { theme } = useSelector((s) => s.platformSetting);

  return (
    <div style={{ background: theme.tertiary_bg }}>
      <Root theme={theme}>
        <Router>
          <WebLayout>
            <Routes>
              <Route path="*" element={<Home />} />
              {/* <Route path="/login" element={<SignIn />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/add-documents" element={<AddDocuments />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/questions" element={<Questions />} />
              <Route path="/pan-verify" element={<PanVerify />} />
              <Route path="/register" element={<RegisterUser />} />
              <Route path="/terms-conditions" element={<TermConditions />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/test" element={<Test />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/glossary" element={<Glossary />} />
              <Route path="/glossary/:id" element={<GlossaryById />} />
              <Route path="/ticket-chat/:id" element={<TicketsChatBox />} />

              <Route path="/form/ITR1" element={<ITR1 />} />
              <Route path="/form/ITR2" element={<ITR2 />} />
              <Route path="/form/ITR3-4" element={<ITR34 />} />
              <Route path="/firm" element={<Firm />} />
              <Route path="/huf-form" element={<HUFForm />} />

              <Route path="/form/filing/ITR1" element={<IRT1Form />} />
              <Route path="/form/filing/ITR2" element={<IRT2Form />} />
              <Route path="/form/filing/ITR3-4" element={<ITR34Form />} /> */}
            </Routes>
          </WebLayout>
        </Router>
      </Root>
    </div>
  );
}

export default App;
const Root = styled.section`
  color: ${(p) => p.theme.primary_txt};
  .max_limit {
    color: ${(p) => p.theme.primary_bg};
    font-size: 16px;
    font-weight: 500;
  }
  .flex_input {
    display: flex;
    gap: 10px;
    align-items: center;
    > div {
      flex: 1;
    }
    &.save_amount {
      background-color: ${(p) => p.theme.secandary_bg};
      padding: 10px;
      margin-top: 10px;
      border-radius: 10px;
      justify-content: space-between;
      > *:first-child {
        flex: 2;
      }
      > *:last-child {
        flex: 1;
      }
    }
  }
  .edit_btn {
    color: ${(p) => p.theme.primary_bg};
  }
  .delete_btn {
    color: #D03838;
  }
  a{
    font-size:14px;
  }
`;
