import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
export default function GlossaryById() {
  const { strings, theme } = useSelector((state) => state.platformSetting);

  const { id } = useParams();
  console.log("id", id);
  return (
    <Root theme={theme}>
      <StyleFormBox>
        <h4>What is Aadhaar?</h4>
        <hr />
        <p>
          Aadhaar, a 12-digit unique identification num- ber issued by the
          Unique Identification Authority of India (UIDAI), is given to every
          resident of India. Serving as a valid proof of identity and address,
          Aadhaar enables access to a span of government and non-government
          services, including banking, telecomm- unications, education,
          healthcare, and more. The system is complexly connected to a biometric
          database that stores the fingerprints and iris scans of the enrolled
          individuals.{" "}
        </p>
      </StyleFormBox>
      <StyleFormBox>
        <h5>Eligibility to obtain Aadhaar</h5>
        <hr />
        <h6>The eligibility criteria for Aadhaar enrolment are as follows:</h6>
        <ol className="list-decimal pl-6">
          <li className="mb-2">
            The applicant is an Indian resident residing in India.
          </li>
          <li className="mb-2">
            The applicant is an Indian resident residing in India.
          </li>
          <li className="mb-2">
            The applicant is an Indian resident residing in India.
          </li>
          <li className="mb-2">
            The applicant is an Indian resident residing in India.
          </li>
        </ol>
        <hr />
        <p>
          Aadhaar, a 12-digit unique identification num- ber issued by the
          Unique Identification Authority of India (UIDAI), is given to every
          resident of India. Serving as a valid proof of identity and address,
          Aadhaar enables access to a span of government and non-government
          services, including banking, telecomm- unications, education,
          healthcare, and more. The system is complexly connected to a biometric
          database that stores the fingerprints and iris scans of the enrolled
          individuals.{" "}
        </p>
      </StyleFormBox>
      <StyleFormBox>
        <h5>Benefits of Aadhaar</h5>
        <hr />
        <ol className="list-decimal pl-6">
          <li className="mb-2">
            Signing legal documents electronically: Aadhaar can be used to sign
            documents online using e-sign services, which are legally valid and
            secure.
          </li>
          <li className="mb-2">
            Opening a bank account with minimal documents: Aadhaar is the sole
            document for opening a bank account without needing any other
            paperwork.
          </li>
          <li className="mb-2">
            Accessing provident funds, LPG connections, subsidies, and
            scholarships: Aadhaar is linked to various government schemes and
            services, such as provident funds, LPG connections, subsidies, and
            scholarships, to ensure direct and hassle-free delivery of benefits
            to the beneficiaries
          </li>
          <li className="mb-2">
            Applying for a passport easily: Aadhaar can expedite obtaining a
            passport, as it can be proof of identity, address, and date of
            birth.
          </li>
          <li className="mb-2">
            Having a universally acceptable proof of identity, address, and age:
            Aadhaar is accepted as a valid proof of identity, address, and age
            by any service provider or agency across the country.
          </li>
          <li className="mb-2">
            Having a unique and portable number linked to biometrics: Aadhaar is
            a 12-digit number unique to every resident and cannot be duplicated
            or forged. It is also portable and can be accessed from anywhere
            using biometric authentication.
          </li>
          <li className="mb-2">
            Including those without ID proof: Aadhaar can help enroll those
            without identity documents, such as the poor and marginalized, using
            the introducer system. This can enable them to access various
            services and benefits that require ID proof.
          </li>
          <li className="mb-2">
            Using self-service facility to update information: Aadhaar holders
            can use the self-service portal or the mAadhaar app to update their
            personal information, such as an address, mobile number, email,
            etc., without visiting any enrolment center.
          </li>
        </ol>
      </StyleFormBox>
    </Root>
  );
}
const Root = styled.section`
  background-color: ${(p) => p.theme.secandary_bg};
  padding: 20px;
  margin: 30px auto 0px;
  border-radius: 10px;
  max-width: 1000px;
  hr {
    margin: 10px 0px;
  }
  p {
    font-size: 16px;
  }
`;
