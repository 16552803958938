import React from 'react'
import { IoInformationCircleOutline } from "react-icons/io5";
import styled from 'styled-components';
export default function ToolTip() {
  return (
    <Root>
        <IoInformationCircleOutline />
    </Root>
  )
}
const Root = styled.div`
width:50px;
    svg{
        font-size: 26px;
        cursor:pointer;
    }
`