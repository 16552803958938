import React from 'react'
import { FaFileUpload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { addOpacity } from '../../utils/helpers';
export default function AllProduct() {
    const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
    <div className='container_box'>
    <img src='/images/products.png' className='m-auto'/>
    <h1 className='text-center text-4xl my-4 font-bold'>Best income tax filing website in India</h1>

    <div className="section_tool flex gap-6 mt-10">
        <div>
          <div className="icon_box">
          <img src={'/images/icon_upload.png'} />
          </div>
          <h4>Maximum tax savings</h4>
          <p>
          Busineses save upto 2-7% of their net GST with us every month. Individuals can save pto ₹86,500 by filing their tax returns through us.
          </p>
        </div>
        <div>
          <div className="icon_box">
          <img src={'/images/icon_upload.png'} />
          </div>
          <h4>Unparalleled Speed</h4>
          <p>
          Experience 3x faster GST filings, 5x faster invoice reconciliation and 10x faster e-waybill generation. Individuals file their tax returns in under 3 min.
          </p>
        </div>
        <div>
          <div className="icon_box">
          <img src={'/images/icon_upload.png'} />
          </div>
          <h4>Accurate Compliance</h4>
          <p>
          Our products are designed and tested by in-house tax experts, ensuring every new clause, form or feature is updated and sent to you over the cloud.
          </p>
        </div>
      </div>
    </div>
   
    </Root>
  )
}
const Root = styled.section`
padding:80px 0px;
background-color: ${(p) => p.theme.tertiary_bg};
color: ${(p) => addOpacity(p.theme.primary_txt, 0.9)};
.section_tool {
    > div {
        background-color: ${(p) => p.theme.secandary_bg};
        padding: 20px;
    border-radius: 20px;
      .icon_box {
        background-color: ${(p) => addOpacity(p.theme.primary_txt, 0.1)};
        width: fit-content;
        padding: 6px;
        font-size: 30px;
        border-radius: 8px;
        margin-bottom: 18px;
      }

      > h4 {
        font-size: 28px;
        font-weight:700;
      }
      > p {
        font-size: 16px;
        margin: 10px 0px;
      }
      > button {
        background-color: ${(p) => p.theme.primary_bg};
        padding: 6px 10px;
        border-radius: 10px;
        font-weight: 600;
        color:${(p) => p.theme.secandary_txt};
      }
    }
  }
`