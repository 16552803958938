import React from "react";
import { useSelector } from "react-redux";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import styled from "styled-components";
export default function TaxSummary() {
  const { strings } = useSelector((s) => s.platformSetting);
  const data = [
    { particulars: "Total Gross Salary", oldRegime: "₹ 0", newRegime: "₹ 0" },
    { particulars: "Slandered Deduction  ", oldRegime: "₹ 0", newRegime: "₹ 0" },
    { particulars: "Net Salary", oldRegime: "₹ 0", newRegime: "₹ 0" },
    { particulars: "House Property Income", oldRegime: "₹ 0", newRegime: "₹ 0" },
    { particulars: "Income from Other Sources", oldRegime: "₹ 0", newRegime: "₹ 0" },
  ];

  return (
    <Root>
      <StyleFormBox>
        <div className="title_bar">
          <h4>{strings.tax.your_tax_summary}</h4>
        </div>
        <hr />
        <div className="flex_row">
          <h6>{strings.tax.gross_salary}</h6>
          <h6>{strings.label.rupee}40,000</h6>
        </div>
        <div className="flex_row">
          <h6>{strings.tax.taxable_income}</h6>
          <h6>{strings.label.rupee}20,000</h6>
        </div>
        <div className="flex_row">
          <h6>{strings.tax.tax_liability}</h6>
          <h6>{strings.label.rupee}5,000</h6>
        </div>
        <div className="flex_row">
          <h6>{strings.tax.taxes_paid}</h6>
          <h6>{strings.label.rupee}18,000</h6>
        </div>
        <hr />
        <div className="bottom_bar">
          <h4>{strings.tax.taxes_payable}</h4>
          <h4>{strings.label.rupee} 15,005</h4>
        </div>
      </StyleFormBox>

      <StyleFormBox>
        <div className="title_bar">
          <h4>{strings.tax.personal_information}</h4>
        </div>
        <hr />
        <div className="flex_row">
          <h6>{strings.label.name}</h6>
          <h6>Rahul Singh</h6>
        </div>
        <div className="flex_row">
          <h6>{strings.label.date_of_birth}</h6>
          <h6>27/08/1998</h6>
        </div>
        <div className="flex_row">
          <h6>{strings.label.pan_number}</h6>
          <h6>DPNPS7878P</h6>
        </div>
        <div className="flex_row">
          <h6>{strings.tax.assessment_year}</h6>
          <h6>2023 - 2024</h6>
        </div>
        <div className="flex_row">
          <h6>{strings.label.residential_status}</h6>
          <h6>Resident</h6>
        </div>
      </StyleFormBox>

      <StyleFormBox>
        <div className="summary_table">
          <table>
            <thead>
              <tr>
                <td>Particulars</td>
                <td>Old Regime</td>
                <td>New Regime</td>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{row.particulars}</td>
                  <td>{row.oldRegime}</td>
                  <td>{row.newRegime}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </StyleFormBox>

      {/* <PrimaryButton title={strings.button.submit} handleClick={()=>{}} /> */}
    </Root>
  );
}
const Root = styled.section`
  .summary_table {
    table {
      width: 100%;
    }
    td {
      /* text-align: center; */
    }
  }
`;
