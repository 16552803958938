import React, { useState } from "react";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Documents from "../component/Profile/Documents";
import Tickets from "../component/Tickets/Tickets";
import TrackStatus from "../component/Profile/TrackStatus";
import PurchaseHistory from "../component/Profile/PurchaseHistory";
import EditProfile from "../component/Profile/EditProfile";
export default function Profile() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root>
      <div className="profile_details">
        <StyleFormBox>
          <div className="text-center">
            <img src="/images/dummy_user.png" className="m-auto" />
            <h2 className="mt-4 text-xl">John Doe</h2>
           <EditProfile />
          </div>
        </StyleFormBox>
        <StyleFormBox>
          <h6>D.O.B</h6> <h5>27-08-2998</h5>
          <hr />
          <h6>Email</h6> <h5>User123@gmail.com</h5>
          <hr />
          <h6>Phone Number</h6> <h5>780-005-5288</h5>
          <hr />
          <button>Delete User</button>
        </StyleFormBox>
      </div>
      <div className="profile_details">
        <StyleFormBox>
          <Documents />
          <hr />
          <Tickets />
          <hr />
          <TrackStatus />
          <hr />
          <PurchaseHistory />
          <hr />
        </StyleFormBox>
      </div>
    </Root>
  );
}
const Root = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 10px;
  display: flex;
  gap: 8px;
  .profile_details:first-child {
    flex: 1;
  }
  .profile_details:last-child {
    flex: 3;
  }
`;
