import React from "react";
import { useSelector } from "react-redux";
import { FaFileUpload } from "react-icons/fa";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { FiArrowRightCircle } from "react-icons/fi";
export default function Products() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <ProductSection theme={theme}>
      <div className="section_tool w-[60%]">
        <div>
          <div className="icon_box">
          <img src={'/images/icon-tpo.png'} />
          </div>
          <h4>Individuals</h4>
          <p>
            Simplify the Income Tax Return filing process at SamosaTax. Just
            upload your Form 16 and get the ITR prepared automatically.
          </p>
          <button>For Individuals <FiArrowRightCircle /></button>
        </div>
        <div>
          <div className="icon_box">
          <img src={'/images/icon-eca.png'} />
          </div>
          <h4>Tax Experts</h4>
          <p>
            Simplify the Income Tax Return filing process at SamosaTax. Just
            upload your Form 16 and get the ITR prepared automatically.
          </p>
          <button>For Tax Experts <FiArrowRightCircle /></button>
        </div>
        <div>
          <div className="icon_box">
          <img src={'/images/icon-tpo.png'} />
          </div>
          <h4>For SMEs</h4>
          <p>
            Simplify the Income Tax Return filing process at SamosaTax. Just
            upload your Form 16 and get the ITR prepared automatically.
          </p>
          <button>Upload Now <FiArrowRightCircle /></button>
        </div>
        <div>
          <div className="icon_box">
          <img src={'/images/icon-eca.png'} />
          </div>
          <h4>Enterprise</h4>
          <p>
            Simplify the Income Tax Return filing process at SamosaTax. Just
            upload your Form 16 and get the ITR prepared automatically.
          </p>
          <button>For Enterprise <FiArrowRightCircle /></button>
        </div>
      </div>
      <div className=" w-[40%]">
        <h6 style={{ color: theme.primary_bg }} className="font-bold ">Our service</h6>
        <h4 className="text-3xl font-bold">Products for everyone</h4>
        <p className="text-lg">
          Built for scale, made by experts and secure by design. Bringing you
          maximum tax savings, unmatched speed and complete peace of mind.
        </p>
      </div>
    </ProductSection>
  );
}
const ProductSection = styled.section`
  padding: 40px 20px;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin:auto;
  .section_tool {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    > div {
      .icon_box {
        background-color: ${(p) => addOpacity(p.theme.primary_txt, 0.1)};
        width: fit-content;
        color: ${(p) => p.theme.primary_bg};
        padding: 6px;
        font-size: 30px;
        border-radius: 8px;
        margin-bottom: 18px;
      }

      > h4 {
        font-size: 28px;
        font-weight:700;
      }
      > p {
        font-size: 16px;
        margin: 10px 0px;
      }
      > button {
        background-color: ${(p) => p.theme.primary_bg};
        padding: 6px 20px;
        border-radius: 50px;
        font-weight: 500;
        color: ${(p) => p.theme.secandary_txt};
        display:flex;
        gap:10px;
        align-items:center;
        font-size: 18px;
      }
    }
  }
`;
