import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Label from "../../styledComponent/Inputs/Label";
import InputField from "../../styledComponent/Inputs/InputField";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import PasswordField from "../../styledComponent/Inputs/PasswordField";
export default function ForgotPassword() {
  const [activeScreen, setActiveScreen] = useState(1);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const handleChange = (index, e) => {
    if (e.target.value.length > 1) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    if (e.target.value !== "" && index < otp.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    setOtp(newOtp);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    if (pastedData.length === otp.length) {
      const newOtp = pastedData.split("").slice(0, otp.length);
      setOtp(newOtp);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <Root theme={theme}>
      {activeScreen == 1 ? (
        <>
          <img src="/images/lock.png" className="mx-auto" />
          <h4 className="text-center mt-12">
            {strings.signUp.forgot_password} ?
          </h4>
          <p className="text-center my-4">
            {strings.signUp.enter_email_or_number}{" "}
          </p>
          <div className="text-left">
            <Label
              label={`${strings.label.phone_number} / ${strings.label.email}`}
            />
            <InputField
              placeholder={`${strings.label.phone_number} / ${strings.label.email}`}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <PrimaryButton
            title={strings.button.send_otp}
            handleClick={() => {
              setActiveScreen(2);
            }}
          />
        </>
      ) : activeScreen == 2 ? (
        <>
          <img src="/images/password.png" className="mx-auto" />
          <h4 className="text-center mt-12">{strings.signUp.verify_otp} ?</h4>
          <p className="text-center my-4">
            {strings.signUp.enter_code_sent_to_email}{" "}
          </p>
          {otp.map((value, index) => (
            <input
              key={index}
              ref={inputRefs[index]}
              type="number"
              maxLength={1}
              className="w-12 h-12 mx-1 text-2xl border border-gray-300 text-center focus:outline-none rounded-xl font-medium otp_input"
              value={value}
              onChange={(e) => handleChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={handlePaste}
            />
          ))}
          <p className="text-center my-4">
            {strings.signUp.if_dont_receive_code} ?{" "}
            <button style={{ color: theme.primary_bg }}> {strings.button.resend}</button>
          </p>
          <PrimaryButton
            title={strings.signUp.verify_otp}
            handleClick={() => {
              setActiveScreen(3);
            }}
          />
        </>
      ) : activeScreen == 3 ? (
        <>
          <img src="/images/keylock.png" className="mx-auto" />
          <h4 className="text-center mt-12">
            {strings.signUp.create_new_password} ?
          </h4>
          <p className="text-center my-4">
            {strings.signUp.new_pass_must_be_different}{" "}
          </p>
          <div className="text-left">
            <Label label={strings.label.password} />
            <PasswordField
              placeholder={strings.label.password}
              error={""}
              value={""}
              handleChange={() => {}}
            />
            <Label label={strings.label.confirm_password} />
            <PasswordField
              placeholder={strings.label.confirm_password}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <PrimaryButton
            title={strings.signUp.reset_password}
            handleClick={() => {
              setActiveScreen(2);
            }}
          />
        </>
      ) : (
        <></>
      )}
    </Root>
  );
}
const Root = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: ${(p) => p.theme.secandary_bg};
  text-align: center;
  .otp_input {
    color: ${(p) => p.theme.primary_txt};
    background-color: transparent;
  }
`;
