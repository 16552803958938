
import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { RiDeleteBin6Fill } from "react-icons/ri";

export default function DeleteButton({handleClick}) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme} onClick={()=>{handleClick()}}><RiDeleteBin6Fill /></Root>
  )
}
const Root = styled.button`
    width:fit-content;
    color:#D03838;
    cursor:pointer;
    font-size:26px;
`