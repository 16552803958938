// rootSaga.js

import { all } from 'redux-saga/effects';
import { ProfileData } from './profile/sagas';

// Root saga combining all sagas
export default function* rootSaga() {
  yield all([
    ProfileData(),
    // Add more sagas here if needed
  ]);
}
