
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from '../../utils/helpers';
export default function TextArea({ placeholder,error,type,value,handleChange }) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
      <textarea  rows="4" cols="50"  placeholder={placeholder} value={value} onChange={(e)=>{handleChange(e.target.value)}}/>
      <p className="error">{error}</p>
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  position: relative;
  textarea {
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid ${(p) =>p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background-color:transparent;
    &::placeholder {
      color: ${(p) => p.theme.primary_txt};
      font-weight: 400;
      font-size: 14px;
    }
  }
  .error{
    font-size: 12px;
    color: red;
    left: 16px;
    position: absolute;
    bottom: -2px;
  }
`;
