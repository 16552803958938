import React, { useState } from "react";
import AccordianBox from "../../styledComponent/Form/AccordianBox";
import Label from "../../styledComponent/Inputs/Label";
import InputField from "../../styledComponent/Inputs/InputField";
import { useSelector } from "react-redux";
import AddMoreButton from "../../styledComponent/Button/AddMoreButton";
import SelectOption from "../../styledComponent/Inputs/SelectOption";
import AddActionButton from "../../styledComponent/Button/AddActionButton";
import AddBankDetails from "../DialogBox/AddBankDetails";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import styled from "styled-components";
import DateField from "../../styledComponent/Inputs/DateField";
import PhoneField from "../../styledComponent/Inputs/PhoneField";
import SecandaryButton from "../../styledComponent/Button/SecandaryButton";
import FormDialogBox from "../DialogBox/FormDialogBox";
import AddMembers from "./DialogBox/AddMembers";
import DeleteButton from "../../styledComponent/Button/DeleteButton";
import AdditionalInfo from "./DialogBox/AdditionalInfo";
export default function PersonalInfo({ handleNext }) {
  const [addMembersBox, setAddMembersBox] = useState(false);
  const [additionalInfoBox , setAdditionalInfoBox]  =  useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const [addBankBox, setAddBankBox] = useState(false);
  return (
    <Root>
      <h4 className="mb-5" style={{ color: theme.primary_txt }}>
        {strings.tax.assessment_year}
      </h4>
      <StyleFormBox>
        <SelectOption
          options={[strings.tax.assessment_year, strings.tax.assessment_year]}
          error={""}
          value={""}
          handleChange={() => {}}
        />
      </StyleFormBox>

      <AccordianBox
        title={strings.tax.permanent_information}
        description={strings.tax.please_provide_all_info_as_per_your_govt_id}
      >
        <div className="flex_input">
          <div>
            <Label label={strings.personal_info.name_of_firm} required={true} />
            <InputField
              placeholder={strings.personal_info.name_of_firm}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.personal_info.date_of_formation} />
            <DateField error={""} value={""} handleChange={() => {}} />
          </div>
        </div>
        <div className="flex_input">
          <div>
            <Label label={strings.label.pan_number} required={true} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div></div>
        </div>
      </AccordianBox>

      <AccordianBox
        title={strings.tax.your_address}
        description={
          strings.tax
            .you_can_provide_either_your_current_address_or_permanent_address
        }
      >
        <div className="flex_input">
          <div>
            <Label
              label={`${strings.address.flat} / ${strings.address.door_no}`}
              required={true}
            />
            <InputField
              placeholder={`${strings.address.flat} / ${strings.address.door_no}`}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.address.premise_name} />
            <InputField
              placeholder={strings.address.premise_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>
        <div className="flex_input">
          <div>
            <Label
              label={`${strings.address.road} / ${strings.address.street}`}
              required={true}
            />
            <InputField
              placeholder={`${strings.address.road} / ${strings.address.street}`}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label
              label={`${strings.address.town} / ${strings.address.city}`}
              required={true}
            />
            <InputField
              placeholder={`${strings.address.town} / ${strings.address.city}`}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>
        <div className="flex_input">
          <div>
            <Label label={strings.address.area_locality} />
            <InputField
              placeholder={strings.address.area_locality}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.address.pincode} required={true} />
            <InputField
              placeholder={strings.address.pincode}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>
        <div className="flex_input">
          <div>
            <Label label={strings.address.state} required={true} />
            <SelectOption
              options={[strings.address.state, strings.address.state]}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.address.country} />
            <SelectOption
              options={[strings.address.country, strings.address.country]}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>

        <div className="flex_input">
          <div>
            <Label label={strings.label.phone_number} />
            <PhoneField
              placeholder={strings.placeholder.enter_10_digit}
              error={strings.document.enter_aadhaar_registered_number}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.label.email} />
            <InputField
              placeholder={strings.placeholder.email_address}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>
        <div className="flex_input">
          <div>
            <Label label={strings.label.secandary_email_address} />
            <InputField
              placeholder={strings.placeholder.email_address}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div></div>
        </div>
      </AccordianBox>

      <AccordianBox
        title={strings.personal_info.verification_information}
        description={strings.personal_info.enter_your_verification_information}
      >
        <div className="flex_input">
          <div>
            <Label
              label={strings.address.name_of_person_filing}
              required={true}
            />
            <InputField
              placeholder={strings.address.name_of_person_filing}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.address.pan_no_of_person_filing} />
            <InputField
              placeholder={strings.label.pan_number}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>
        <div className="flex_input">
          <div>
            <Label label={strings.personal_info.gender} />
            <div className="flex gap-4">
              <div className="flex-1">
                <input
                  type="radio"
                  id="aadhaar"
                  name="otp_evc"
                  // value={strings.tax.old_regime}
                  onChange={(e) => {
                    // setOtpEvcOption(e.target.value);
                  }}
                />
                <label htmlFor="aadhaar"> {strings.personal_info.male}</label>
              </div>
              <div className="flex-1">
                <input
                  type="radio"
                  id="verify_otp"
                  name="otp_evc"
                  // value={strings.tax.new_regime}
                  onChange={(e) => {
                    // setOtpEvcOption(e.target.value);
                  }}
                />
                <label htmlFor="verify_otp">
                  {" "}
                  {strings.personal_info.female}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="flex_input">
          <div>
            <Label label={strings.label.date_of_birth} />
            <DateField error={""} value={""} handleChange={() => {}} />
          </div>
          <div>
            <Label label={strings.label.father_name} />
            <InputField
              placeholder={strings.label.father_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>

        <div className="flex_input">
          <div>
            <Label label={strings.label.aadhar_number} />
            <InputField
              type={"number"}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div></div>
        </div>
      </AccordianBox>

      <AccordianBox
        title={strings.personal_info.members}
        description={
          strings.tax
            .you_can_provide_either_your_current_address_or_permanent_address
        }
      >
        <div className="text-center">
          {" "}
          <AddMoreButton title={strings.button.upload_file} />
        </div>
        <SecandaryButton
          title={strings.button.add_manually}
          handleClick={() => {
            setAddMembersBox(true);
          }}
        />
      </AccordianBox>

      <StyleFormBox>
        <div className="flex-1 flex items-center justify-between">
          <h4 style={{ color: theme.primary_txt }}>
            {strings.address.additional_info}
          </h4>
          <AddActionButton
            title={strings.button.add}
            handleChange={() => {
              setAdditionalInfoBox(true);
            }}
          />
        </div>
        <p>{strings.personal_info.additional_info_about_partner_member}</p>
        <div className="additional_info_section mt-5">
          <StyleFormBox>
            <div className="flex gap-2 justify-between">
              <h4>Lorem Ipsum is simply dummy text </h4>
              <DeleteButton handleClick={() => {}} />
            </div>
            <h5>Partner Name : Lorem Ipsum</h5>
            <h5>Admitted/Retired : Lorem Ipsum</h5>
            <h5>PAN Number : XXXXXXXX</h5>
          </StyleFormBox>
          <StyleFormBox>
            <div className="flex gap-2 justify-between">
              <h4>Lorem Ipsum is simply dummy text </h4>
              <DeleteButton handleClick={() => {}} />
            </div>
            <h5>Partner Name : Lorem Ipsum</h5>
            <h5>Admitted/Retired : Lorem Ipsum</h5>
            <h5>PAN Number : XXXXXXXX</h5>
          </StyleFormBox>

        </div>
      </StyleFormBox>

      <AccordianBox
        title={strings.tax.pan_details}
        description={strings.tax.pan_linking_is_mandatory_requirement}
      >
        <div className="flex_input">
          <div>{strings.label.name}</div>
          <div>Rahul Singh</div>
        </div>
        <div className="flex_input">
          <div>{strings.label.date_of_birth}</div>
          <div>27/01/2000</div>
        </div>
        <div className="flex_input">
          <div>{strings.label.pan_number}</div>
          <div>DRPUTB755865GJ</div>
        </div>
        <div className="flex_input">
          <div>{strings.label.father_name}</div>
          <div>Mohan Lal</div>
        </div>
      </AccordianBox>

      {/* Form Link your PAN*/}
      <StyleFormBox>
        <div className="flex-1 flex items-center justify-between">
          <h4 style={{ color: theme.primary_txt }}>
            {strings.tax.add_bank_details}
          </h4>
          <AddActionButton
            title={strings.button.add}
            handleChange={() => {
              setAddBankBox(true);
            }}
          />
        </div>
      </StyleFormBox>
      {/* 


      {/* dialog box for adding bank details */}
      <AddBankDetails
        open={addBankBox}
        handleClose={() => {
          setAddBankBox(false);
        }}
        handleCallback={() => {}}
      />

      {/* Form for Adding members*/}
      <FormDialogBox
        open={addMembersBox}
        formTitle={strings.personal_info.members}
        handleClose={() => {
          setAddMembersBox(false);
        }}
      >
        <AddMembers
          handleSubmit={() => {
            setAddMembersBox(false);
          }}
        />
      </FormDialogBox>
      
      {/* Form for add additional info */}
      <FormDialogBox
        open={additionalInfoBox}
        formTitle={strings.address.additional_info}
        handleClose={() => {
          setAdditionalInfoBox(false);
        }}
      >
        <AdditionalInfo
          handleSubmit={() => {
            setAdditionalInfoBox(false);
          }}
        />
      </FormDialogBox>
    </Root>
  );
}
const Root = styled.section`
  .additional_info_section {
    display: flex;
    gap: 10px;
    > section {
      flex: 1;
    }
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
`;
