import React, { useState } from "react";
import styled from "styled-components";
import Label from "../../../../styledComponent/Inputs/Label";
import SelectOption from "../../../../styledComponent/Inputs/SelectOption";
import { useSelector } from "react-redux";
import InputField from "../../../../styledComponent/Inputs/InputField";
import TextArea from "../../../../styledComponent/Inputs/TextArea";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";
import SecandaryButton from "../../../../styledComponent/Button/SecandaryButton";
export default function AddGoodsCarriage({ handleSubmit }) {
    const [businessDetails , setBusinessDetails] = useState({
      code:'',
      name:'',
      description:'',
    })
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const handleFormChange = (key,value)=>{
    setBusinessDetails({...businessDetails , [key]:value})
  }
  return (
    <Root>
      <div>
        <div>
          <Label label={strings.income.number_of_months} />
          <InputField
              error={""}
              value={businessDetails.name}
              handleChange={(value) => {handleFormChange('name',value)}}
            />
        </div>
        <div>
          <Label label={strings.income.income_per_vehicle_per_month} />
          <InputField
              error={""}
              value={businessDetails.name}
              handleChange={(value) => {handleFormChange('name',value)}}
            />
        </div>
        <div>
          <Label label={strings.income.registration_number} />
          <InputField
              error={""}
              value={businessDetails.name}
              handleChange={(value) => {handleFormChange('name',value)}}
            />
        </div>
        <div>
          <Label label={strings.income.ownership_status} />
          <SelectOption
            options={[strings.income.ownership_status, strings.income.ownership_status]}
            error={""}
            value={businessDetails.code}
            handleChange={(value) => {handleFormChange('code',value)}}
          />
        </div>
      </div>

      <div className="flex items-center gap-3">
      <SecandaryButton title={strings.button.add} handleClick={()=>handleSubmit(businessDetails)}/>
      <PrimaryButton title={strings.button.save_and_continue} handleClick={()=>handleSubmit(businessDetails)}/>
      </div>
    </Root>
  );
}
const Root = styled.section``;
