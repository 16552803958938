import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import ConfirmationBox from "../DialogBox/ConfirmationBox";
import { FaCheck } from "react-icons/fa";
import Label from "../../styledComponent/Inputs/Label";
import PasswordField from "../../styledComponent/Inputs/PasswordField";

export default function EVerifyReturn() {
  const [activeScreen, setActiveScreen] = useState("e_verify");
  const [otpEvcOption, setOtpEvcOption] = useState("");
  const [evcOption, setEvcOption] = useState("");
  const [verifyBox, setVerifyBox] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const handleChange = (index, e) => {
    if (e.target.value.length > 1) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    if (e.target.value !== "" && index < otp.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    setOtp(newOtp);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    if (pastedData.length === otp.length) {
      const newOtp = pastedData.split("").slice(0, otp.length);
      setOtp(newOtp);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  console.log("activeScreen otpEvcOption  ====>", evcOption);
  return (
    <Section theme={theme} className="min_container">
      {activeScreen === "e_verify" ? (
        <div className="section">
          <h4>{strings.document.e_verify_return} </h4>
          <p>{strings.document.validate_and_allow_to_process}</p>
          <hr className="my-4 opacity-25" />
          <h4>{strings.document.option_to_receive_otp_evc} </h4>
          <div className="my-4">
            <input
              type="radio"
              id="aadhaar"
              name="otp_evc"
              value={strings.signUp.aadhaar_registered_mobile_number}
              onChange={(e) => {
                setOtpEvcOption(e.target.value);
              }}
            />
            <label htmlFor="aadhaar">
              {" "}
              {strings.signUp.aadhaar_registered_mobile_number}
            </label>
          </div>
          <div className="my-4">
            <input
              type="radio"
              id="verify_otp"
              name="otp_evc"
              value={strings.signUp.enter_otp_to_verify}
              onChange={(e) => {
                setOtpEvcOption(e.target.value);
              }}
            />
            <label htmlFor="verify_otp"> {strings.document.bank_evc}</label>
          </div>

          {otpEvcOption === strings.signUp.aadhaar_registered_mobile_number && (
            <PrimaryButton
              title={strings.button.continue}
              addClass={"mt-5"}
              handleClick={() => {
                setActiveScreen("aadhaar_otp");
              }}
              disable={otpEvcOption.length === 0}
            />
          )}
        </div>
      ) : otpEvcOption === strings.signUp.aadhaar_registered_mobile_number &&
        activeScreen === "aadhaar_otp" ? (
        <div className="section">
          <div>
            <h3
              className="font-medium text-center"
              style={{ color: theme.primary_txt }}
            >
              {strings.signUp.enter_opt_sent_to}
            </h3>

            <div className="flex items-center justify-center mt-8">
              {otp.map((value, index) => (
                <input
                  key={index}
                  ref={inputRefs[index]}
                  type="number"
                  maxLength={1}
                  className="w-12 h-12 mx-1 text-2xl border border-gray-300 text-center focus:outline-none rounded-xl font-medium otp_input"
                  value={value}
                  onChange={(e) => handleChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={handlePaste}
                />
              ))}
            </div>
            <p className="text-center my-4">
              {strings.signUp.if_dont_receive_code} ?{" "}
              <button style={{ color: theme.primary_bg }}>
                {" "}
                {strings.button.resend}
              </button>
            </p>
          </div>
          <PrimaryButton
            title={strings.signUp.verify_otp}
            addClass={"mt-5"}
            handleClick={() => {
              setVerifyBox(true);
            }}
          />
        </div>
      ) : (
        ""
      )}
      {activeScreen === "e_verify" &&
      otpEvcOption === strings.signUp.enter_otp_to_verify ? (
        <div className="section">
          <h4>{strings.document.generate_evc}</h4>
          <div className="my-4">
            <input
              type="radio"
              id="net_banking"
              name="otp_evc"
              value={strings.document.through_net_banking}
              onChange={(e) => {
                setEvcOption(e.target.value);
              }}
            />
            <label htmlFor="net_banking">
              {" "}
              {strings.document.through_net_banking}
            </label>
            {evcOption == strings.document.through_net_banking && (
              <p>
                Once you click on Continue. you will be logged out of e-Filling
                portal and will be redirected to a page. where you can select
                bank to login through net banking
              </p>
            )}
          </div>
          <div className="my-4">
            <input
              type="radio"
              id="bank_account"
              name="otp_evc"
              value={strings.document.through_bank}
              onChange={(e) => {
                setEvcOption(e.target.value);
              }}
            />
            <label htmlFor="bank_account">
              {" "}
              {strings.document.through_bank}
            </label>
            {evcOption === strings.document.through_bank && (
              <p>
                You have validate and enable EVC on bank account number
                xxxxxxxxxxx3597
              </p>
            )}
          </div>
          <div className="my-4">
            <input
              type="radio"
              id="demate_account"
              name="otp_evc"
              value={strings.document.through_demat}
              onChange={(e) => {
                setEvcOption(e.target.value);
              }}
            />
            <label htmlFor="demate_account">
              {" "}
              {strings.document.through_demat}
            </label>
            {evcOption === strings.document.through_demat && (
              <p>
                You have validate and enable EVC on bank account number
                xxxxxxxxxxx3597
              </p>
            )}
          </div>

          <PrimaryButton
            title={strings.button.continue}
            addClass={"mt-5"}
            handleClick={() => {
              setActiveScreen("evc_otp");
            }}
          />
        </div>
      ) : activeScreen === "evc_otp" &&
        otpEvcOption === strings.signUp.enter_otp_to_verify ? (
        <div className="section">
          <Label label={strings.document.enter_evc} />
          <p className="my-2">
            Sent to your verified mobile number: 99XXXXXX78 and verified email
            ID: teXX.@gmail.com
          </p>
          <PasswordField error={""} value={""} handleChange={() => {}} />

          <p className="text-center my-4">
              <button style={{ color: theme.primary_bg }}>
                {strings.button.resend_otp}  {" "}
              </button>
              {" "}
              {strings.document.allowed_once}
            </p>
            <PrimaryButton
            title={strings.signUp.verify_otp}
            addClass={"mt-5"}
            handleClick={() => {
              setVerifyBox(true);
            }}
          />
        </div>
      ) : (
        ""
      )}

      {/* Dialog Box  */}
      <ConfirmationBox
        open={verifyBox}
        handleClose={() => {
          setVerifyBox(false);
        }}
      >
        <div className="text-center">
          <div
            className="icon text-3xl bg-green-500 w-auto mx-auto my-4 mb-8 rounded-full p-4 inline-block"
            style={{ color: theme.secandary_txt }}
          >
            <FaCheck />
          </div>
          <h4>{strings.document.e_verification}</h4>
          <p>
            Your ITR filing for FY 2022-23 is now 100% completed. You'll soon
            receive a confirmation email from the IT department.
          </p>
          <hr className="my-4 opacity-25" />
          <h4 className="text-left">{strings.document.filling_details}</h4>
          <div className="flex items-center justify-between my-2">
            <h5>Filled PAN:</h5>
            <h4>XYZ0072CXIO</h4>
          </div>
          <div className="flex items-center justify-between my-2">
            <h5>Acknowledgement :</h5>
            <h4>1265894785</h4>
          </div>
          <div className="flex items-center justify-between my-2">
            <h5>ITR Type:</h5>
            <h4>ITR 1</h4>
          </div>
          <hr className="my-4 opacity-25" />
          <h4 className="text-left">{strings.document.filling_status}</h4>
          <div className="flex items-center justify-between my-2">
            <h5>e-Filing</h5>
            <h4>
              {" "}
              <FaCheck />
            </h4>
          </div>
          <div className="flex items-center justify-between my-2">
            <h5>e-Verification</h5>
            <h4>
              {" "}
              <FaCheck />
            </h4>
          </div>
          <div className="flex items-center gap-4 mt-4">
            <PrimaryButton title={strings.button.continue} />
          </div>
        </div>
      </ConfirmationBox>
      {/* Dialog Box  */}
    </Section>
  );
}

const Section = styled.section`
  .section {
    padding: 20px;
    margin-top: 30px;
    background-color: ${(p) => p.theme.secandary_bg};
    border-radius: 10px;
  }
  .otp_input {
    color: ${(p) => p.theme.primary_txt};
    background-color: transparent;
  }
`;
