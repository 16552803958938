import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import InputField from "../../styledComponent/Inputs/InputField";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import SelectOption from "../../styledComponent/Inputs/SelectOption";
import Label from "../../styledComponent/Inputs/Label";
export default function AddBankDetails({open,handleClose,handleCallback}) {
  const { theme ,strings} = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme} className={open && 'active'}>
      <button onClick={() => {handleClose()}} className="close_btn_bg"></button>
      <div className="dialogBox">
        <div className="title">
          <h1 style={{color:theme.primary_txt}}>AddBankDetails</h1>
          <button className="close_btn" onClick={() => {handleClose()}} style={{color:theme.primary_txt}}><IoClose /></button>
        </div>
        <div className="content">
        <Label label={strings.label.select_bank}/>
        <SelectOption
            options={[
              strings.label.select_bank,
              strings.label.select_bank,
            ]}
            error={""}
            value={""}
            handleChange={() => {}}
          />

           <div className="flex justify-between gap-4">
          <div className="flex-1"> <Label label={strings.label.account_number}/>
           <InputField
              placeholder={strings.label.account_number}
              error={""}
              value={""}
              handleChange={() => {}}
            /></div>
          <div className="flex-1"> <Label label={strings.label.ifsc_code}/>
           <InputField
              placeholder={strings.label.ifsc_code}
              error={""}
              value={""}
              handleChange={() => {}}
            /></div>
           </div>

           <Label label={strings.label.account_name}/>
           <InputField
              placeholder={strings.label.account_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />

           <Label label={strings.label.account_type}/>
           <SelectOption
            options={[
              strings.label.account_type,
              strings.label.account_type,
            ]}
            error={""}
            value={""}
            handleChange={() => {}}
          />
          <PrimaryButton title={strings.button.save_and_continue} addClass={'mt-5'}/>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000042;
  backdrop-filter: blur(2px);
  top: 0px;
  left: 0px;
  display:none;
  z-index:10;
  &.active{
    display:unset
  }
  .close_btn_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: transparent;
  }
  .close_btn{
    background: ${p => p.theme.tertiary_bg};
    border: none;
    font-size: 20px;
    border-radius: 4px;
  }
  .dialogBox {
    position: absolute;
    left:50%;
    top:20px;
    transform: translateX(-50%);
    background: ${(p) => p.theme.secandary_bg};
    width:50%;
    border-radius: 10px;
    padding: 16px;
    .title{
      display:flex;
      align-items:center;
      justify-content:space-between;
      border: 2px solid ${(p) => p.theme.border_clr};
      border-width: 0px 0px 2px 0px;
      padding-bottom: 14px;
    }
    .content{
      margin-top:20px;
     
    }
  }
`;
