import React, { useState } from "react";
import InputField from "../styledComponent/Inputs/InputField";
import PrimaryButton from "../styledComponent/Button/PrimaryButton";
import styled from "styled-components";
import { FcGoogle } from "react-icons/fc";
import { useSelector } from "react-redux";
import SocialButton from "../styledComponent/Button/SocialButton";
import { FaApple } from "react-icons/fa";
import { addOpacity } from "../utils/helpers";
import Label from "../styledComponent/Inputs/Label";
import PasswordField from "../styledComponent/Inputs/PasswordField";
import { NavLink } from "react-router-dom";

export default function SignIn() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
           <Label
              label={`${strings.label.phone_number} / ${strings.label.email}`}
            />
            <InputField
              placeholder={`${strings.label.phone_number} / ${strings.label.email}`}
              error={""}
              value={""}
              handleChange={() => {}}
            />

            <Label label={strings.label.password} />
            <PasswordField
              placeholder={strings.label.password}
              error={""}
              value={""}
              handleChange={() => {}}
            />
            <NavLink to="/password-reset" className="forgot_pass">{strings.signUp.forgot_password} ? </NavLink>
          
            <PrimaryButton
              title={strings.button.sign_in}
              handleClick={() => {}}
            />

            <h5 className="or_tab">{strings.signUp.or_continue_with}</h5>
            <div className="social_btns">
              <SocialButton>
                <button onClick={() => {}}>
                  <FcGoogle />
                </button>
              </SocialButton>
              <SocialButton>
                <button onClick={() => {}}>
                  <FaApple />
                </button>
              </SocialButton>
              <SocialButton>
                <button onClick={() => {}}>
                  <FcGoogle />
                </button>
              </SocialButton>
            </div>
    </Root>
  );
}
const Root = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: ${(p) => p.theme.secandary_bg};
  .forgot_pass {
    text-align: center;
    color: ${(p) => addOpacity(p.theme.primary_txt, 0.4)};
    background: none;
    border: none;
    margin: auto;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin: 60px auto 20px auto;
    cursor: pointer;
  }
  .or_tab {
    color: ${(p) => addOpacity(p.theme.primary_txt, 0.9)};
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 30px;
    &::after {
      content: "";
      background: linear-gradient(to right, #bdbdbd, #c1c1c100);
      width: 32%;
      height: 4px;
      display: block;
      top: 50%;
      right: 0px;
      position: absolute;
      transform: translateY(-50%);
    }
    &::before {
      content: "";
      background: linear-gradient(to left, #bdbdbd, #c1c1c100);
      width: 32%;
      height: 4px;
      display: block;
      top: 50%;
      left: 0px;
      position: absolute;
      transform: translateY(-50%);
    }
  }
  .social_btns {
    display: flex;
    justify-content: center;
    gap: 14px;
    margin-top: 30px;
  }
`;
