import { SET_PLATFORM_STRING, SET_PLATFORM_THEME } from "../constant";

const initialState = {
  strings: {
    label: {
      rupee: "₹",
      phone_number: "Phone Number",
      email: "Email",
      password: "Password",
      confirm_password: "Confirm Password",
      name: "Name",
      first_name: "First Name",
      middle_name: "Middle Name",
      last_name: "Last Name",
      date_of_birth: "Date of Birth",
      pan_number: "Pan Number",
      nature_of_employment: "Nature of Employment",
      select_bank: "Select Bank",
      account_number: "Account Number",
      ifsc_code: "IFSC Code",
      account_name: "Account Name",
      account_type: "Account Type",
      base_pay: "Basic Pay",
      house_rent_allowace: "House Rent Allowance",
      lta_allowance: "LTA Allowance",
      standard_deductions_16ia: "Standard Deductions 16(ia)",
      professional_tax_under_section_16iii:"Professional tax under section 16(iii)",
      relief_under_section_89: "4B Relief under section 89",
      income_claimed_for_relief_under_sectio_89a:"4C Income claimed for relief under section 89A",
      employer_name: "Employer Name",
      employer_category: "Employer Category",
      total_tax_deduxted_source: "Total tax deducted at source",
      employer_tax: "Employer TAN",
      gross_salary: "Gross Salary",
      balance: "Balance",
      residential_status: "Residential Status",
      aadhar_number: "Aadhaar Number",
      aadhaar_enrollement_no: "Aadhaar Enrollment No",
      pan: "PAN",
      secandary_mobile_number: "Secondary mobile number",
      secandary_email_address: "Secondary email address",
      landline_number: "Landline number",
      father_name: "Father’s Name",
      choose_option: "Choose Option",
      type: "Type",
      description: "Description",
      time_period: "Time Period",
      max_limit: "Max Limit",
      your_message: "Your Message",
      not_applicable:'Not Applicable',
      this_field_is_optional:' (This field is optional) '
    },
    personal_info:{
      name_of_firm:'Name of Firm',
      date_of_formation:'Date of Formation',
      verification_information:'Verification Information',
      enter_your_verification_information:'Please enter your Verification Information here.',
      gender:'Gender',
      male:'Male',
      female:'Female',
      members:'Members',
      additional_info_about_partner_member:'Additional Information about Partner/Member of Firm, Co-op Society, LLP, etc.',
      choose_status_of_member_partner:'Choose the Status of Member/Partner',
      share_percentage:'Share Percentage',
      partner_remuneration:'Partner Remuneration',
      partner_interest:'Partner Interest',
      partner_interest_allowable:'Partner Interest Allowable',
      partner_interest_percent:'Partner Interest Percent',
      partner_member_address:'Partner (or Member) Address',
      was_and_change_in_previous_year:'Was there any change during the previous year in the Partners / Members of the Firm/AOP/BOI/LLP etc',
      partner_name:'Partner Name',
      admitted_retired:'Admitted/Retired',
      date_of_admission_retirement:'Date Of Admission Or Retirement',
      remuneration_paid_in_retiring_partner:'Remuneration paid/payable in case of retiring Partner',
      percent_of_share:'Percent Of Share',
      recognition_number_alootted_by_dpiit_for_startup:'Recognition number allotted by the DPIIT for a startup',
      you_enter_either_current_or_permanent_address:'You can enter either your current or permanent address here.',
      name_of_huf:'Name of HUF',

    },
    tax: {
      personal_info: "Personal info",
      gross_income: "Gross Income",
      tax_saving: "Tax Saving",
      tax_summary: "Tax Summary",
      assessment_year: "Assessment Year",
      permanent_information: "Permanent Information",
      please_provide_all_info_as_per_your_govt_id:
        "Please provide all info as per your government identity documents(PAN, Aadhaar etc.)",
      identification_and_contact_Details: "Identification & Contact details",
      to_efile_your_returns_please_provide_your_aadhaar_pan:
        "To e-file your returns, please provide your Aadhaar, PAN and contact details",
      your_address: "Your Address",
      you_can_provide_either_your_current_address_or_permanent_address:"You can provide either your current address or permanent address of residence.",
      link_your_pan: "Link your PAN",
      pan_linking_is_mandatory_requirement_to_efile:
        "PAN Linking is a mandatory requirement to e-file your returns.",
      add_bank_details: "Add Bank Details",
      salary_breakup: "Salary Breakup",
      salary_per_section_17: "Salary as per section 17(1)",
      deductions_and_relief: "Deductions and Relief",
      employer_tds_details: "Employer & TDS Details",
      popular_deduction: "Popular Deduction",
      health_insurance_nps_interest_earned:
        "80C (PPF, Life Insurance, ELSS Mutual funds etc.), Health Insurance, NPS, Interest earned on Savings Bank Account",
      more_deductions: "More Deductions",
      donations_to_charitable_organizations:
        "Donations to charitable organizations, educational loan, house rent for self employed and more",
      any_other_deductions: "Any other Deductions",
      interest_on_home_loan_electric_vehicle_loan:
        "Interest on home loan, electric vehicle loan, royalty received on books and more",
      taxes_paid_tds_tcss: "Taxes Paid, TDS and TCSS",
      taxes_deducted_collected_source_advance_tax_or_sel_assessment:
        "Taxes deducted or collected at source, Advance Tax or Self Assessment Tax already paid; you can Upload Form 26ASto fetch these details.",
      your_tax_summary: "Your tax summary",
      gross_salary: "Gross Salary",
      taxable_income: "Taxable Income",
      tax_liability: "Tax Liability",
      taxes_paid: "Taxes Paid",
      taxes_payable: "Taxes Payable",
      personal_information: "Personal Information",
      additional_information: "Additional Information (Optional)",
      leave_empty_if_you_dont_have:
        "Leave empty if you don’t have additional information",
      pan_details: "PAN Details",
      pan_linking_is_mandatory_requirement:
        "PAN Linking is a mandatory requirement to e-file your returns.",
      salary_income: "Salary Income",
      you_can_add_salary_income: "You can add salary income from multiple jobs",
      total_amount: "Total Amount",
      perquisites_under_section: "Perquisites under section 17(2)",
      profits_in_lieu_of_salary:
        "Profits in lieu of salary under section 17(3)",
      income_under_section_89a_notified_country:
        "Income under section 89A - notified country",
      income_under_section_89a_other_country:
        "Income under section 89A - other than notified country",
      income_taxable_claimed_in_earlier_year:
        "Income taxable during the previous year on which relief u/s 89A was claimed in any earlier previous year.",
      exempt_allowances_under_section_10: "Exempt allowances under section 10",
      exempt_allowances: "Exempt allowances",
      income_chargeable_under_head_salaries:
        "Income chargeable under head salaries (3-4A-4C)",
      other_source_income: "Other Source Income",
      fixed_deposite_savings_bank_interest_agriculture:
        "Fixed Deposits, Savings Bank Interest, Agriculture, Online Gaming and any Other Income Sources",
      house_property_income: "House Property Income",
      add_details_earned_rent_from_property:"Please add the details if you earned rent from your house property or paid interest on housing loan",
      detailed_computation:'Detailed Computation',
      switch_regime:'Switch Regime',
      old_regime:'Old Regime',
      new_regime:'New Regime',
    },
    income: {
      interest_income: "Interest income",
      dividend_income: "Dividend Income",
      agricultural_income: "Agricultural Income",
      exempt_income: "Exempt Income",
      any_other_income: "Any Other Income",
      interest_income_from_saving_bank: "Interest Income from Savings Bank",
      interest_from_deposit: "Interest Income from Deposits",
      interest_income_from_depoits:"Interest from sweep accounts converted to FDs post-office fixed deposits etc",
      please_add_details_if_you_have_recurring_income:"Please add the details if you have any recurring income as interest earned from your bank including post-office savings bank",
      bank_name: "Name of Bank",
      amount: "Amount",
      interest_income_on_pf: "Interest Income on PF",
      please_add_details_of_taxable_interest_from_pf:"Please add the details if you have any Taxable interest from PF",
      interest_on_income_tax_refund: "Interest on Income Tax Refund",
      income_tax_refund_last_year:"If you had an income tax refund last year, you may have received some interest amount along with the refund amount",
      enter_the_interest_amount:"Enter the interest amount only, do not enter the entire refund amount",
      any_other_interest_income: "Any Other Interest Income",
      bonds_unsecured_loans_national_certificates:"Bonds, unsecured loans, national savings certificates etc",
      choose_income_type: "Choose Your Income Type",
      choose_property_type: "Choose Your Property Type",
      dividend_income_from_equities:"Dividend Income from Equities, Stocks, Mutual Funds, etc",
      dividend_from_investment: "Dividend from investments in Equities, Stocks",
      total_dividend_income: "Total Dividend Income",
      narration: "Narration",
      date_of_receipt: "Date of Receipt",
      income_details: "Income Details",
      gross_agricultural_receipt: "Gross Agricultural Receipt",
      expenditure_on_agricultural: "Expenditure On Agricultural",
      unabsorbed_agricultural_loss: "Unabsorbed Agricultural Loss",
      agriculture_land_details: "Agriculture Land Details",
      optional_fields_enter_if_receipt_exceeds_5_lakhs:"These fields are optional. Enter these values if Net Agriculture Receipt exceeds Rs. 5 Lakhs",
      interest_income_from_ppf: "Interest Income from PPF",
      example_interest_earned_on_ppf: "Example: Interest earned on PPF",
      interest_income_from_nre_account: "Interest Income from NRE Accounts",
      interest_earned_from_non_residential_external:"Interest earned from Non Residential External Accounts",
      source: "Source",
      online_gaming_lottery_gifts_and_more:"Online Gaming, Lottery, Gifts and more",
      income_lottery_winnings_games_crossword_puzzles_horse_races:"Income from Lottery Winnings, Games, Crossword Puzzles, Horse Races, Gambling, Betting and more under Section 115BB ",
      income_from_other_source: "Income from Other Sources",
      report_other_income_which_not_part_of_income_from_salary:"Report any other income which is not part of Income from Salary, House Property, Capital Gain or Business and Profession. Gifts can be declared as Income here.",
      income_from_retirement_account_usa_uk_canada:"Income from Retirement Accounts (USA, UK, Canada)",
      applicable_for_claiming_relief_under_section_89a:"Applicable for claiming relief under Section 89A",
      income_claimed_for_relief_from_taxtation:"Income claimed for relief from taxtation u/s 89A",
      note_that_you_should_submit_form_10ef_the_income_tax_department_order:"Note that you should submit Form 10EE to the Income Tax Department in order to claim this relief.",
      income_from_retirement_benefit_account_maintained:"Income from retirement benefits account maintained in a non-notified country",
      income_taxable_during_the_previous_year_which_relief_89a_was_claimed:"Income taxable during the previous year on which relief u/s 89A was claimed in any earlier previous year",
      deductible_expenses_and_depreciation:"Deductible Expenses and Depreciation (Section 57) ",
      claim_deductions_for_expenses_related_to_income:"Claim deductions for expenses related to income from other sources",
      self_occupied_property: "Self-occupied Property",
      rental_property: "Rental Property",
      deemed_let_out_proprty: "Deemed Let Out Property",
      interest_paid_housing_loan_for_this:
        "Interest paid on housing loan for this house property",
      interest_paid_on_loan_for_this_house_property:
        "Interest paid on loan for this house property",
      interest_paid_during_the_pre_construction:
        "Interest paid during the pre-construction period of house property",
      financial_year_previous_complete_house_property:"Financial year previous to completion of house property",
      your_house_address: "Your House Address",
      annual_rent_recieved: "Annual Rent Received by you",
      municipal_tax: "Municipal Tax",
      total_interest_amount_paid_during_pre_construction:"Total interest amount paid during pre-construction period",
      tenant_details: "Tenant Details",
      name_of_the_tenant: "Name of the Tenant",
      pan_tan_of_the_tenant: "PAN/TAN of the Tenant",
      aadhaar_of_the_tenant: "Aadhaar of the Tenant",
      estimated_annual_rent_receivable: "Estimated Annual Rent Receivable",
      professional_freelencing_business_income:"Professional, Freelancing and Business Income",
      for_all_professionals:"For Doctors, Lawyers, CAs, Other Professionals, Freelancers, Small & Medium businesses, Tutors, Influencers etc.",
      professional_income_total: "Professional Income (Total Expenses)",
      financial_particulars: "Financial Particulars",
      mandatory_presumptive_income:"Mandatory if you have presumptive income from professions or businesses",
      total_assets: "Total Assets",
      total_liabilities: "Total Liabilities",
      add_gst_details: "Add GST Details",
      capital_gain_income: "Capital Gain Income",
      stocks_funds_and_more:"Stocks, Mutual Funds, Land, Bonds, RSUs, US Stocks, Jewellery and more",
      professional_income: "Professional Income",
      first_select_profession_type:"You can first select the profession type and then specify the nature of profession",
      profession_type: "Profession Type",
      nature_of_profession: "Nature of Profession",
      gross_revenue_total: "Gross Revenue(Total Sales or Turnover)",
      total_expenses: "Total Expenses",
      taxable_income_under_44ADA:"Net taxable income u/s 44ADA(Gross Revenue - Total Expenses)",
      business_income: "Business Income",
      goods_carriages_section_44AE: "Goods Carriages Under Section 44AE ",
      regular_business_professional_income:"Regular Business & Profession Income",
      other_business_income: "Other Business Income",
      business_type: "Business Type",
      nature_of_business: "Nature of Business",
      name_of_business:'Name of Business',
      total_gross_revenue_sale: "Total Gross Revenue(Total Sales or Turnover)",
      total_gross_revenue: "Total Gross Revenue",
      revenue_recived_via_online:"Revenue received via Online or Digital Modes",
      revenue_received_via_cash: "Revenue received via Cash/Non-Digital Modes",
      total_income_profit_your_calculation: "Total Income/Profit as per your calculation",
      from_online_digital_modes: "From online or digital modes",
      business_details: "Business Details",
      goods_carriage_details: "Goods Carriage Details",
      stocks_mutual_funds_land_bonds_jewellery: "Stocks, Mutual Funds, Land, Bonds,  Jewellery and more",
      crypto_income: "Crypto Income (Virtual Digital Assets - VDA)",
      add_income_from_crypto_trades_staking_minting:"Add income from Crypto - trades, staking, mining, minting, gifts, rewards, airdrops, NFT or other VDA assets",
      stocks_mutual_funds: "Stocks & Mutual Funds",
      import_transactions_from_all_stocks:"Import transactions from all your stocks or mutual funds brokers seamlessly with just one click. You can upload P&L files too or add data yourself.",
      sale_of_land_building: "Sale of Land or Building",
      gains_for_sale_of_land_residential_commercial_buildings:"Gains from sale of land, residential or commercial buildings and other real estate properties",
      stock_options_rsu: "Stock Options & RSUs",
      gains_from_sale_of_vested_restricted_stock_units:"Gains from Sale of vested Restricted Stock Units (RSUs) and exercised Stock Options, ESOPs",
      bonds_and_debentures: "Bonds and Debentures",
      gains_loss_from_bonds_and_debentures_including_govt:"Gains or losses from Bonds and Debentures including Government, Corporate and Tax-free Bonds",
      deemed_capitial_gains: "Deemed Capital Gains",
      capital_gains_previously_exempted_subject_to_taxation:"Capital gains previously exempted but are now subject to taxation",
      any_other_assets: "Any Other Assets",
      jewellery_paintings_sculptures_archaeological_collections:"Jewellery, Paintings, Sculptures, Archaeological Collections, and any other relevant capital assets",
      add_equity_shares_mutual_funds: "Add Equity Shares & Mutual Funds",
      stocks:'Stocks',
      mutual_funds:'Mutual Funds',
      select_asset:'Select type of asset',
      date_of_sale:'Date of Sale',
      date_of_exercise:'Date of Exercise',
      add_details_asset:'Add Details of the asset',
      description_of_asset_sold:'Description of Asset Sold',
      total_sale_price:'Total Sale Price',
      transfer_expenses:'Transfer Expenses',
      did_you_pay_stt:'Did you pay STT ?',
      add_property_details:'Add Property Details',
      applicable_if_you_have_sold_land:'Applicable if you have sold land or building(s)',
      date_of_purchase:'Date of Purchase',
      total_purchase_price:'Total Purchase Price ',
      value_pf_property_which_stamp_duty:'Value of Property on which stamp duty paid (in Rs.)',
      do_you_want_add_cose_improvment_details:'Do you want to add cost of improvement details ?',
      expenses_incurred_increasing_value_of_property:'Expenses incurred for increasing the value of the property',
      buyer_details:'Buyer Details',
      please_provide_details_of_property_buyer:'Please provide details of the property buyer.',
      buyer_name:'Buyer Name',
      ownership_percentage:'Ownership Percentage',
      amount_paid:'Amount Paid ',
      add_stock_options_rsu:'Add Stock Options & RSUs',
      type_of_stock_option:'Type of Stock Option / RSUs',
      add_transaction_details:'Add transaction details',
      total_sale_price:'Total Sale Price ',
      value_as_date_of_exercise:'Value as on date of exercise',
      bonds_debentures:'Bonds & Debentures',
      add_bonds_debentures:'Add Bonds & Debentures',
      specify_those_you_sold_redeemed:"Specify those that you've sold or redeemed in 2022-23",
      select_type_bond:'Select type of bond',
      sale_value:'Sale Value',
      purchase_price:'Purchase Price',
      transfer_expenses_if_any:'Transfer Expenses if any',
      short_term_capital_gain:'Short Term Capital Gain',
      long_term_captial_gain:'Long Term Capital Gain',
      whether_amount_captial_gain_during_previous_year:'Whether any amount of unutilized capital gain on asset transferred during the previous years shown below was deposited in the Capital Gains Accounts Scheme within due date for that year?',
      prev_year_asset_transferred:'Prev. year in which asset transferred',
      section_under_deduction_claimed_in_year:'Section under which deduction claimed in that year',
      year_which_new_asset_acquired:'Year in which new asset acquired/ constructed',
      amount_utilised_captial_gains:'Amount utilised out of capital Gains Account',
      amount_used_for_new_asset:'Amount not used for new asset',
      amount_deemed_to_short_term_captial_gain:'Any other amount deemed to be short term capital gain',
      total_amount_deemed_to_short_term_captial:'Total amount deemed to be short term capital gains',
      add_other_assets:'Add Any Other Assets',
      add_crypto_income_yourself:'Add Crypto / VDA income yourself',
      choose_option_shared_by_crypto_exchange:"Choose this option in-case you don't have reports shared by your Crypto exchange or you prefer adding data yourself through form",
      add_gains_nft:'Add gains from NFT',
      enter_crypto_vda_asset_name:'Enter Crypto / VDA asset name',
      gains:'Gains',
      enter_nft_asset_name:'Enter NFT asset name',
      add_business_details:'Add Business Details',
      business_code:'Business Code ',
      choose_business_code:'Choose Business Code',
      name_of_business:'Name of the Business',
      number_of_months:'Number of Months',
      income_per_vehicle_per_month:'Income Per Vehicle Per Month',
      registration_number:'Registration Number',
      ownership_status:'Ownership Status',
      add_goods_carriage_details:'Add Goods Carriage Details',
      provide_details_of_profession_or_business:'Please provide details of your profession(s) or Business(s)',
      balance_sheet:'Balance Sheet',
      details_of_assets_liabilities_of_business:'Details of assets and liabilities of your business/ profession as on 31st March, 2023',
      p_l_account:'P&L Account',
      details_income_expenses_of_your_business_profession_for_period:'Details of income and expenses of your business/ profession for the period - April 2022 to March 2023',
      depreciation:'Depreciation',
      summary_depreciation_of_assets_under_income_tax_act:'Summary of depreciation on all assets under the Income-Tax Act.',
      schedule_icds:'Schedule ICDS',
      effect_of_income_computation:'Effect of Income Computation and Disclosure Standards on Profit',
      schedule_esr:'Schedule ESR',
      deduction_under_section_35:'Deduction under section 35 (expenditure on scientific research)',
     
    },
    balance_sheet:{
      you_run_sme_msme_business_provide_balance_sheet:'Do you run a SME/MSME business? Do you have more details to provide in Balance Sheet?',
      assets:'Assets',
      enter_details_of_assets:'Enter details of all assets owned by you - immovable property, movable property and financial assets.',
      have_you_invested_in_shares_mutual_funds:'Have you invested in shares or mutual funds?',
      long_term_investments_shares_mf:'Long term investments (Shares/MFs)',
      short_term_investments_share_mf:'Short term investments (Shares/MFs)',
      short_term_investments_debentures:'Short term investments (Debentures)',
      amount_receivable_from_your_clients:'Amount receivable from your clients',
      cash_in_hand:'Cash in hand',
      balance_in_bank:'Balance in bank',
      loan_given_to_friends_and_family:'Loan given to friends and family',
      liabilities:'Liabilities',
      enter_details_of_payables_long_term_and_short_term_loans:'Enter details of payables, long term and short term loans and advances',
      capital_investment:'Capital Investment',
      secured_loan:'Secured Loan',
      unsecured_loan:'Unsecured loan',
      are_you_see_you_want_to_fill_the_balance_sheet:'Are you sure you want to fill the Detailed Balance Sheet?',
      applicable_only_you_have_these:'Applicable only if you have any of these:',
      reserves_surplus:'Reserves & Surplus',
      foreign_bank_loans:'Foreign Bank Loans',
      finished_goods_inventories_other_assets:'Finished goods inventories & Other assets',
      sme_msme_businesses:'SME/MSME businesses',
      professions_with_revenue_above_lakhs:'Professions with revenue above 50 Lakhs',
      balance_sheet_for_itr3:'Balance Sheet for ITR-3',
      sources_of_funds:'Sources of Funds',
      applications_of_funds:'Applications of Funds',
      manufacturing_account:'Manufacturing Account',
      trading_account:'Trading Account',
      profit_and_loss_account:'Profit & Loss Account',
      profit_loss:'Profit And Loss',
      run_sme_msme_business_details_to_provide_in_profit_loss:'Do you run a SME/MSME business? Do you have more  details to provide in Profit and Loss?',
      profit_income_expenses:'Profit(Income - Expenses)',
      proprietor_fund_1:"1: Proprietor's fund",
      proprietor_capital:"1a: Proprietor's capital",
      reserves_and_surpus:"1b: Reserves and Surplus",
      revaluation_reserve:"1bi: Revaluation Reserve",
      capital_reserve:"1bii: Capital Reserve",
      statutory_reserve:"1biii: Statutory Reserve",
      any_other_reserve:"1biv: Any other Reserve",
      total_:"1bv: Total (bi+bii+biii+biv)",
      // :"1c: Total proprietor's fund (a + bv)",
      // :"2: Loan funds",
      // :"2a: Secured loans",
    },
    tax_saving: {
      section_80c_investment: "Section 80c - Investment ",
      add_up_all_your_80c_deductions_specify:
        "Add up all your 80C deductions and specify the total amount here",
      section_80c_medical_insurance:
        "Section 80D - Medical Insurance and Preventive Health Checkup",
      decuction_for_medical_insurance:
        "Deductions for Medical Insurance or Preventive Health Check-Up fees or Medical Expenditure incurred by you.",
      do_you_have_medical_insurance:
        "Do you have a medical insurance for self, family or parents?",
      medical_insurance_premium: "Medical insurance Premium",
      self_and_family: "Self and Family",
      parents: "Parents",
      expenditure_can_claimed_only_for_senior_citizens: `This expenditure can be claimed only for senior citizens who don't have medical insurance policy`,
      section_80tta_deduction_for_interest:
        "Section 80TTA - Deduction for Interest earned on Savings Bank Account",
      interest_earned_on_saving_bank_account:
        "Interest earned on Savings Bank Account",
      section_80ccc_contributio_pension_plan:
        "Section 80CCC - Contribution to Pension Plan / Annuity Fund",
      contribution_amount_pension_plan:
        "Contribution amount to Pension Plan / Annuity Fund",
      section_80ccd_employee_contribution_to_pension_scheme:
        "Section 80CCD (1) and (1B) - Employee Contribution to New Pension Scheme (NPS)",
      please_enter_your_own_contribution:
        "please enter your own contribution to NPS",
      section_80ccd_employer_contribution:
        "Section 80CCD(2) - Employer contribution to NPS",
      enter_employer_contribution_to_nps:
        "Enter the employer contribution to NPS",
      section_80g_donations_charitable_organizations:
        "Section 80G - Donations to charitable organizations",
      did_you_donate_any_amount_to_charitable_organization_in_last_financial_year:
        "Did you donate any amount to charitable organizations in the last financial year?",
      section_80e_education_loan_on_higher_studies:
        "Section 80E - Education Loan on higher studies (Graduate or PostGraduate)",
      interest_on_higher_education_loan_paid_this_year:
        "Enter the interest on higher education loan paid this year",
      section_80gg_deduction_for_house_rent:
        "Section 80GG - Deduction for House Rent (Self-employed or Salaried with no HRA)",
      enter_rent_paid_per_month: "Enter the rent paid per month",
      number_of_months: "Number of months",
      section_80gga_donations_for_scientific_research_or_rural_development:
        "Section 80GGA - Donations for Scientific Research or Rural Development",
      govt_requires_itemized_details_donations_for_section_80gga:
        "The government requires itemized details of donations for Section 80GGA.",
      section_80dd_disable_dependent_deducton:
        "Section 80DD - Disable dependent deduction. (Spouse/Children/Parents)",
      add_dependent_with_40_disability: "Add Dependent with 40% Disability",
      section_80u_self_disability: "Section 80U - Self Disability",
      offers_tax_benefits_if_individual_suffers_disability:
        "Offers tax benefits if an individual suffers a disability.",
      disability: "Disability",
      section_80ee_interest_on_home_loan:
        "Section 80EE - Interest on Home Loan",
      interest_on_first_house_loan_2016_2017:
        "Interest on first-house loan(loan should be sanctioned between Apr 2016 and Mar 2017)",
      section_80eea_interest_on_home_loan:
        "Section 80EEA - Interest on Home Loan",
      interest_on_first_house_loan_2019_2022:
        "Interest on first-house loan(loan should be sanctioned between Apr 2019 and Mar 2022),",
      section_80eeb_electric_vehicle_loan:
        "Section 80EEB - Electric Vehicle Loan",
      criteria_of_section_80eeb:
        "Read about the eligibility criteria of Section 80EEB: Deduction under section 80EEB is available on interest on loan taken for the purchase of an electric vehicle",
      section_80qqb_royalty_received_on_books:
        "Section 80QQB - Royalty received on books",
      enter_the_royaly_fee_received_by_authors:
        "Enter the royalty/copyright fee or lumpsum received by authors of literary/scientific/artistic books",
      section_80rrb_income_on_parents:
        "Section 80RRB - Income on Parents/Inventions",
      deductions_amount_for_patentee: "Enter the deduction amount for patentee",
      section_80ggc_contribution_to_political_party:
        "Section 80GGC - Contribution to political party",
      enter_the_cotribution_made_to_political_party:
        "Enter the contribution or donation made to a political party",
      section_80jja_bio_degradable:
        "Section 80JJA - Bio-degradable waste business",
      deduction_exempting_profits_from_bio_degradable_waste_business:
        "Enter the deduction exempting profits from bio-degradable waste business",
      section_80ddb_deductons_for_treatment_specified_diseases:
        "Section 80DDB: Deductions for treatment of specified diseases and ailments",
      age_of_person_who_deduction_being_claimed:
        "Age of person for whom deduction is being claimed",
      medical_treatment_costs_for_specified_deseases:
        "Medical treatment costs for specified diseases under Section 80DDB",
      section_80cch: "Section 80CCH",
      contribution_to_agnipath_scheme: "Contribution to Agnipath scheme",
      tax_paid_tds_tcs: "Taxes Paid, TDS and TCS",
      taxes_collected_source_advance_tax_from_26as:
        "Taxes deducted or collected at source, Advance Tax or Self Assessment Tax already paid; you can Upload Form 26ASto fetch these details.",
      tds_on_salary: "TDS on Salary",
      salary_tds_entries_if_anything_missing:
        "This is your Salary TDS entries. If there’s anything missing, kindly check the income sources page",
      non_salary_tds: "Non Salary TDS",
      add_entries_from_tds_on_income_professional_income:
        "Add the entries from TDS on interest income, professional or consulting income ",
      tds_on_sale_of_immovable_property:
        "TDS on Sale/Rent of Immovable Property",
      if_sell_land_the_buyer_have_deducted_tds_at_rate_1:
        "If you sell/rent land, house, property or building etc (immovable property) etc, the buyer/tenant may have deducted TDS at the rate of 1% on the sale price of the property/rental and issued you a TDS certificate.You need to specify those TDS deduction details here.",
      taxes_collected_at_source: "Taxes collected at source",
      add_all_tcs_entries_for:
        "Add all the TCS entries for ex : on purchase of cars",
      self_tax_payments: "Self tax payments",
      add_entries_here_if_voluntarily_deposited_taxes:
        "Add entries here if you voluntarily deposited taxes to the government via Challan 280.",
      donation_details: "Donation Details",
      comman_80g_organizations: "Common 80G Organizations",
      name_of_donee: "Name of Donee",
      donation_amount_cash: "Donation Amount (Cash)",
      donation_amount_non_cash: "Donation Amount (Non Cash)",
      pan_of_donee: "PAN of Donee",
      limit_on_deduction: "Limit on Deduction",
      no_limit: "No Limit",
      subject_to_income: "Subject To income",
      qualifying_percentage: "Qualifying Percentage",
      deduction_80gga: "Deduction 80GGA",
      clause_under_which_donation_is_made:
        "Clause under which donation is made",
      tds_non_salary: "TDS - Non Salary",
      tax_deducted_at_source: "Tax Deducted at source",
      provide_this_information_for_tds:
        "Provide this information from TDS certificate provided by deductor",
      tan_of_deductor: "TAN of Deductor",
      name_of_deductor: "Name of Deductor",
      total_tax_deducted: "Total Tax Deducted",
      income_against_which_tds_was_deducted:
        "Income against which TDS was deducted",
      type_of_income: "Type of Income",
      financial_year_tds_was_deducted:
        "Financial Year in which TDS was deducted",
      tds_sale_rent_immovavle_property: "TDS - Sale/Rent of Immovable Property",
      pan_of_property_buyer: "PAN of Property Buyer / Tenant",
      name_of_property_buyer: "Name of Property Buyer / Tenant",
      claim_transfer_tds_credits:
        "Claim ot Transfer TDS credits from another assessee",
      claim_transfer_tcs_credits:
        "Claim ot Transfer TCS credits from another assessee",
      tax_deducted_claimed: "Tax Deducted claimed for this year",
      tax_collected_source: "Tax Collected At Source",
      provide_information_from_tds:
        "Provide this information from TCS certificate provided by deductor",
      tan_of_collector: "TAN of Collector",
      name_of_collector: "Name of Collector",
      total_tax_collector: "Total Tax Collector",
      tax_collected_claimed_year: "Tax Collected claimed for this year",
      expenditure_year_tcs_deducted:
        "Expenditure Year in which TCS was deducted",
      financial_year_tcs_deducted: "Financial Year in which TCS was deducted",
    },
    form: {
      auto_fetch_details_upload_form_16:
        "Auto-Fetch your details effortlessly by uploading Form 16",
      pre_fill_in_just_min:
        "Don’t have form 16? Pre-fill & e-file in just 3 mins ",
      choose_your_form_16: "Choose your Form-16 PDF to upload",
      upload_password_protected_pdf:
        "You can upload a password protected PDF too. Please choose one file at a time.",
    },
    document: {
      choose_your_document: "Choose Your Document",
      aadhaar_card: "Aadhaar Card",
      verify_aadhaar_card: "Verify your Aadhaar Card",
      aadhaar_card_number: "Aadhaar Card Number",
      enter_12_digit_aadhaar_number: "Enter 12 digit Aadhaar number",
      enter_aadhaar_registered_number:
        "Please enter Aadhaar registered mobile number",
      cards: "Cards",
      upi: "UPI",
      e_verify_return: "E-verify IT Returns",
      validate_and_allow_to_process:
        "Validate and allow the IT department to process your return.",
      option_to_receive_otp_evc: "Choose an option to receive OTP/EVC",
      bank_evc: "Bank EVC (Electronic Verification Code)",
      e_verification: "e-Verification Successful",
      generate_evc: "Generate electronic verification code (EVC)",
      through_bank: "Through Bank Account",
      through_net_banking: "Through Net Banking",
      through_demat: "Through Demat Account",
      net_banking_note:
        "Once you click on Continue. you will be logged out of  e-Filling portal and will be redirected to a page. where you can select bank to login through net banking",
      disclaimer: "Disclaimer",
      filling_details: "Filling Details",
      filling_status: "Filling Status",
      enter_evc: "Enter EVC ",
      allowed_once: "Allowed only once",
    },
    placeholder: {
      enter_12_digit: "Enter 12 digit number",
      enter_10_digit: "Enter 10 digit number",
      enter_28_digit: "Enter 28 digit number",
      enter_landline_no: "Enter your landline tel",
      email_address: "email.123@gmail.com",
      organizations_80g: "80G organizations",
      name_of_donee_organization: "Name of the donee organization",
      pan_of_donee_here: "Enter PAN of Donee here",
      address_of_donee: "Address of Donee",
      enter_pin_code: "Enter PIN Code here",
      enter_name_of_donee: "Enter Name of the Donee here",
      pan_of_tenant: "PAN of Tenant",
      name_of_buyer: "Name of Buyer",
      yes: "Yes",
      no: "No",
      enter_10_digit_pan: "Enter 10 digit PAN",
      type_your_text: "Type your text here...",
    },
    message: {
      enter_if_you_donated_to_govt:"Enter ‘GGGGG0000G’ if you have donated to Government Funds that doesn’t have a PAN",
      amount_is_same_as_tax_deducted:"Usually this amount is same as Total Tax Deducted.",
      amount_is_same_as_tax_collected: "Usually this amount is same as Total Tax Collected",
      financial_year_of_return:"This is the same as financial year of the return (2022-23)",
      leave_if_not_deducted_in_name:"Leave it blank if not deducted in the name of assessee",
      this_is_same_as_financial_year:"This is the same as financial year of the return (2022-23) ",
      date_format: "Specify date in a format like DD/MM/YYYY",
      max_total_expenses:"Total expenses should be less than 50% of gross revenue",
      max_gross_revenue:"Gross revenue should be less than 50 Lakhs Total Expenses",
      should_at_least_6per_gross_revenue:"Should be at least 6% of gross revenue From Cash or Non-Digital Modes",
      should_at_least_8per_gross_revenue: "Should be at least 8% of gross revenue",
      received_any_interest_coupon_payment_in_same_financial_year:'NOTE : If you have received any interest or coupon payment for this investment in the same financial year, please specify it in the Interest Income Section',
    },
    address: {
      flat: "Flat",
      door_no: "Door No",
      block_no: "Block No",
      premise_name: "Premise Name",
      road: "Road",
      street: "Street",
      area_locality: "Area / Locality",
      pincode: "PIN code",
      city: "City",
      state: "State",
      name_of_district: "Name of District",
      measurement_acers: "Measurement (Acers)",
      ownweship_status: "Ownership Status",
      water_source: "Water Source",
      country: "Country",
      co_owners_details: "Co-Owners Details",
      in_case_this_property_is_jointly: "In case this property is jointly owned, please specify co-owners",
      name_of_co_owner: "Name of Co-owner",
      pan_of_co_owner: "PAN of Co-owner",
      percentage_stage: "Percentage Share",
      address_line: "Address Line",
      property_address:'Property Address',
      town:'Town',
      name_of_person_filing:'Name of Person Filing ',
      pan_no_of_person_filing:'PAN Number of Person Filing',
      additional_info:'Additional info',
    },
    button: {
      next: "Next",
      sign_in: "Sign In",
      submit: "Submit",
      link_your_pan: "Link Your PAN",
      add_bank: "Add Bank",
      add: "Add",
      add_more_item: "Add More Items",
      upload_form_16: "Upload Form 16",
      send_otp: "Send OTP",
      continue: "Continue",
      save_and_continue: "Save and Continue",
      view_details: "View details",
      save_amount: "Save Amount",
      add_entry: "Add Entry",
      export_data: "Export data",
      import_data: "Import data",
      download_cleartax_template: "Download ClearTax Template",
      add_new_donation: "Add New Donation",
      upload_from_26as: "Upload Form 26AS",
      add_manually: "Add Manually",
      resend: "Resend",
      continue_without_form_16: "Continue without Form 16",
      e_verify: "E Verify",
      download: "Download",
      resend_otp: "Resend OTP",
      edit_profile: "Edit Profile",
      ticketing_inhouse_chat: "Ticketing - In house Chat",
      edit_itr:'Edit  ITR',
      file_itr:'File ITR',
      upload_file:'Upload File',
      detailed_balance_sheet:'Detailed Balance Sheet',
      yes_fill_detailed:'Yes, Fill Detailed',
      go_back:'Go Back',
      detailed_profit_loss:'Detailed Profit and Loss',
    },
    signUp: {
      forgot_password: "Forgot Password",
      old_password: "Old Password",
      new_password: "New Password",
      or_continue_with: "Or continue with",
      enter_number_to_otp: "Enter your mobile number to get OTP",
      enter_opt_sent_to: "Enter the OTP sent to",
      enter_email_or_number:
        "Please enter your email / phone number to receive a verification code",
      verify_otp: "Verify OTP",
      enter_code_sent_to_email:
        "Please enter 4 digit code sent to your email / phone number",
      if_dont_receive_code: "If you don’t receive the code",
      create_new_password: "Create New Password",
      new_pass_must_be_different:
        "New password must be different from previously used password",
      reset_password: "Reset Password",
      verify_your_pan: "Verify your PAN (Mandatory step by IT Department)",
      pan_card_number: "PAN Card Number",
      choose_method_for_verifying_otp:
        "Choose a method for verifying OTP and we will prepare income tax returns in minutes",
      it_department_registered: "IT Department registered mobile number",
      aadhaar_registered_mobile_number: "Aadhaar registered mobile number",
      enter_otp_to_verify: "Enter OTP to Verify",
    },
    settings: {
      chat_support: "Chat Support",
      manage_payment_methods: "Manage Payment Methods",
    },
    profile: {
      documents: "Documents",
      verified_document: "Verified Document",
      successfully_verified_document:
        "You have successfully verified your document and added on your documents.",
      add_document: "Add Document",
      payment_successful: "Payment Successful",
      ticket_number: "Ticket Number",
      select_service: "Select Service ",
      date: "Date",
      contact_us: "Contact Us",
      about_us: "About us",
    },
  },
  theme: {
      primary_bg: '#FFA53B',
      secandary_bg: '#FFFFFF',
      tertiary_bg: '#EBEBEB',
      primary_txt: '#151515',
      secandary_txt: '#FAFAFA',
      border_clr: '#DDDDDD',
      green_txt: '#58C232',
      red_txt: '#D03838',
  },
  themeMode: "Light",
  activeTheme: "Orange",
};

export const platformSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLATFORM_STRING:
      return {
        ...state,
        strings: action.payload,
      };
    case SET_PLATFORM_THEME:
      return {
        ...state,
        theme: action.payload[0],
        themeMode: action.payload[1],
        activeTheme: action.payload[2],
      };

    default:
      return state;
  }
};
