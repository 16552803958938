// sagas.js

import { takeLatest, put } from 'redux-saga/effects';
import { GET_ALL_DATA, UPDATE_ALL_DATA } from '../constant';

// Saga function to fetch user data
function* fetchUserDataSaga() {
  try {
    console.log('fetchUserDataSaga working  =============> ')
    const userData = 'this is update'
    yield put({ type: UPDATE_ALL_DATA, payload: userData });
  } catch (error) {
    yield put({ type: 'FETCH_USER_DATA_ERROR', error });
  }
}

// Saga watcher
export function* ProfileData() {
  yield takeLatest(GET_ALL_DATA, fetchUserDataSaga);
}
