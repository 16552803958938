import React, { useState } from "react";
import { useSelector } from "react-redux";
import ConfirmationBox from "../DialogBox/ConfirmationBox";
import { FaUser } from "react-icons/fa";
import styled from "styled-components";
import InputField from "../../styledComponent/Inputs/InputField";
import Label from "../../styledComponent/Inputs/Label";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
export default function EditProfile() {
  const [profileEditBox, setProfileEditBox] = useState(false);
  const [userImage, setUserImage] = useState();

  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root>
      <button
        style={{ color: theme.primary_bg }}
        onClick={() => {
          setProfileEditBox(true);
        }}
      >
        {strings.button.edit_profile}
      </button>
      <ConfirmationBox
        open={profileEditBox}
        handleClose={() => {
          setProfileEditBox(false);
        }}
      >
        <div className="edit_box">
          <h4 className="mb-5">Update Profile</h4>
          <div className="image_select w-40 h-40 rounded-full block mx-auto  border-6 overflow-hidden relative">
            <input
              type="file"
              className="h-full w-full cursor-pointer opacity-0 relative z-20"
              onChange={(e) => {
                setUserImage(e.target.files);
              }}
            />
            {userImage ? (
              <img
                src={URL.createObjectURL(userImage[0])}
                className="absolute top-0 left-0 h-full w-full object-cover"
              />
            ) : (
              <span
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-7xl z-10"
                style={{ color: theme.primary_bg }}
              >
                <FaUser />
              </span>
            )}
          </div>
          <div className="flex justify-between gap-3 text-left">
            <div className="w-full">
              <Label label={strings.label.first_name} />
              <InputField
                placeholder={strings.label.first_name}
                error={""}
                value={""}
                handleChange={() => {}}
              />
            </div>
            <div className="w-full">
              <Label label={strings.label.last_name} />
              <InputField
                placeholder={strings.label.last_name}
                error={""}
                value={""}
                handleChange={() => {}}
              />
            </div>
          </div>
          <PrimaryButton title={strings.button.save_and_continue} handleClick={()=>{}} addClass={'mt-5'}/>
        </div>
      </ConfirmationBox>
    </Root>
  );
}
const Root = styled.div`
  .image_select {
    background-color: ${(p) => p.theme.secandary_bg};
    border: 4px solid ${(p) => p.theme.tertiary_bg};
  }
`;
