import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
export default function AboutUs() {
    const { strings, theme } = useSelector((s) => s.platformSetting);

  return (
    <Root theme={theme}>
      <img src="/images/about_bg.png" />
      <div className="container_section">
        <h4 className="text-4xl my-4">Why SamosaTax ?</h4>
        <p className="text-xl">
          SamosaTax is a national accounting and advisory services firm
          dedicated to helping entrepreneurial, middle-market companies and high
          net worth individuals achieve their goals. Since 1951, clients have
          chosen Marcum for our deep expertise and insightful guidance in
          helping them forge pathways to success, whatever challenges they’re
          facing.
        </p>
        <p className="text-xl">
          Taxation offers a complete spectrum of tax, assurance and advisory
          services, as well as an extensive portfolio of industry-focused
          practices with specialized expertise for both privately held and
          publicly registered companies, and nonprofit and social sector
          organizations.
        </p>
        <button>READ MORE</button>
      </div>
    </Root>
  );
}
const Root = styled.section`
  position: relative;
  margin-top:60px;
  .container_section {
    position: absolute;
    width: 1000px;
    background: ${p=> addOpacity(p.theme.secandary_bg , 0.6)};
    backdrop-filter: blur(15px);
    left: 50%;
    transform: translateX(-50%);
    text-align:center;
    padding:20px;
    border-radius:10px;
    top:-16px;
    > button {
        margin-top:20px;
        background-color: ${(p) => p.theme.primary_bg};
        padding: 6px 30px;
        border-radius: 50px;
        font-weight: 600;
        color:${(p) => p.theme.secandary_txt};
      }
  }
  
`;
