import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Label from "../../../../styledComponent/Inputs/Label";
import SelectOption from "../../../../styledComponent/Inputs/SelectOption";
import DateField from "../../../../styledComponent/Inputs/DateField";
import InputField from "../../../../styledComponent/Inputs/InputField";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import ToggelButton from "../../../../styledComponent/Button/ToggelButton";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";
import TextArea from "../../../../styledComponent/Inputs/TextArea";
import PercentageField from "../../../../styledComponent/Inputs/PercentageField";
import AddMoreButton from "../../../../styledComponent/Button/AddMoreButton";
import AddActionButton from "../../../../styledComponent/Button/AddActionButton";
export default function SaleOfLandBuilding({ handleSubmit }) {
  const [activeTab, setActiveTab] = useState("");
  const [formData, setFormData] = useState({
    select_type_of_asset: "",
    date_of_sale: "",
    date_of_exercise: "",
    description_of_asset_sold: "",
    _total_sale_price: "",
    transfer_expenses: "",
    total_purchase_price: "",
    check_stt: "",
  });
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const handleFormData = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const submitForm = () => {
    handleSubmit(formData);
  };
  useEffect(() => {
    setActiveTab(strings.income.stocks);
  }, []);
  return (
    <Root theme={theme}>
      <div>
        <StyleFormBox>
          <h4>{strings.income.add_property_details}</h4>
          <p>{strings.income.applicable_if_you_have_sold_land}</p>
          <hr />

          <div className="flex_input my-2">
            <Label label={strings.income.date_of_sale} />
            <DateField error={""} value={""} handleChange={() => {}} />
          </div>

          <div className="flex_input my-2">
            <Label label={strings.income.date_of_purchase} />
            <DateField error={""} value={""} handleChange={() => {}} />
          </div>
        </StyleFormBox>

        <StyleFormBox>
          <h4>{strings.income.add_details_asset}</h4>
          <hr />
          <div className="flex_input my-2">
            <Label label={strings.income.description_of_asset_sold} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.total_sale_price} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.transfer_expenses} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.value_pf_property_which_stamp_duty} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
        </StyleFormBox>
        <StyleFormBox>
          <div className="flex_input my-2 justify-between">
            <h4>{strings.income.do_you_want_add_cose_improvment_details}</h4>
            <ToggelButton />
          </div>
          <p>{strings.income.expenses_incurred_increasing_value_of_property}</p>

          <div className="flex_input my-2">
            <Label label={strings.label.description} />
            <TextArea error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.amount} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
        </StyleFormBox>
        <StyleFormBox>
          <h4>{strings.address.property_address}</h4>
          <hr />
          <div className="flex_input my-2">
            <Label label={strings.address.address_line} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.address.town + "/" + strings.address.city} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.address.pincode} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>

          <div className="flex_input my-2">
            <Label label={strings.address.state} required={true} />
            <SelectOption
              options={[strings.address.state, strings.address.state]}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.address.country} />
            <SelectOption
              options={[
                strings.address.country,
                strings.address.country,
                strings.address.country,
              ]}
              error={""}
              value={activeTab}
              handleChange={() => {
                // setActiveTab(e);
              }}
            />
          </div>
        </StyleFormBox>
        <StyleFormBox>
          <h4>{strings.income.buyer_details}</h4>
          <p>{strings.income.please_provide_details_of_property_buyer}</p>
          <hr />
          <div className="flex_input my-2">
            <Label label={strings.income.buyer_name} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.ownership_percentage} />
            <PercentageField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.label.aadhar_number} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.label.pan} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.amount_paid} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="text-right mt-5">
            <AddMoreButton title={strings.button.add_more_item} />
            <AddActionButton
              title={strings.button.add}
              handleChange={() => {}}
            />
          </div>
        </StyleFormBox>
        <PrimaryButton
          title={strings.button.submit}
          handleClick={() => {
            submitForm();
          }}
          addClass={"mt-4"}
        />
      </div>
    </Root>
  );
}
const Root = styled.section``;
