import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ResetPassword from "../component/Settings/ResetPassword";
import ChatSupport from "../component/Settings/ChatSupport";
export default function Settings() {
  const [activeTab, setActiveTab] = useState("");
  const { strings, theme } = useSelector((s) => s.platformSetting);
  useEffect(()=>{
    setActiveTab(strings.signUp.reset_password)
  },[])
  return (
    <Root theme={theme}>
      <div className="tab_section">
        <button
          onClick={() => {
            setActiveTab(strings.signUp.reset_password);
          }}
          className={activeTab == strings.signUp.reset_password ? "active" : ""}
        >
          {strings.signUp.reset_password}
        </button>
        <button
          onClick={() => {
            setActiveTab(strings.settings.manage_payment_methods);
          }}
          className={
            activeTab == strings.settings.manage_payment_methods ? "active" : ""
          }
        >
          {strings.settings.manage_payment_methods}
        </button>
        <button
          onClick={() => {
            setActiveTab(strings.settings.chat_support);
          }}
          className={
            activeTab == strings.settings.chat_support ? "active" : ""
          }
        >
          {strings.settings.chat_support}
        </button>
      </div>
      <div className="content_section">
      {activeTab == strings.signUp.reset_password ?  <ResetPassword/> : activeTab === strings.settings.chat_support ?  <ChatSupport/>:''}
      </div>
    </Root>
  );
}
const Root = styled.div`
  max-width: 1000px;
  background-color: ${(p) => p.theme.secandary_bg};
  padding: 20px;
  margin: 30px auto 0px;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  .tab_section {
    border: 1px solid ${(p) => p.theme.border_clr};
    padding: 0px 10px 10px 10px;
    border-radius: 10px;
    >button{
        display:block;
        text-align:left;
        border: 1px solid ${(p) => p.theme.border_clr};
        padding:10px;
        border-radius: 10px;
        margin:10px 0px;
        width: 100%;
        transition: all 0.5s;
        &:hover{
            background-color: ${(p) => p.theme.tertiary_bg};
        }
        &.active{
            background-color: ${(p) => p.theme.tertiary_bg};
        }
    }
  }
  .content_section {
    flex: 1;
    border: 1px solid ${(p) => p.theme.border_clr};
    padding: 10px;
    border-radius: 10px;
  }
`;
