import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Label from "../../../../styledComponent/Inputs/Label";
import SelectOption from "../../../../styledComponent/Inputs/SelectOption";
import DateField from "../../../../styledComponent/Inputs/DateField";
import InputField from "../../../../styledComponent/Inputs/InputField";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import ToggelButton from "../../../../styledComponent/Button/ToggelButton";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";
import ToolTip from "../../../../styledComponent/ToolTip";
import TextArea from "../../../../styledComponent/Inputs/TextArea";
import AddMoreButton from "../../../../styledComponent/Button/AddMoreButton";
import AddActionButton from "../../../../styledComponent/Button/AddActionButton";
export default function AddOtherAssets({ handleSubmit }) {
  const [activeTab, setActiveTab] = useState("");
  const [formData, setFormData] = useState({
    select_type_of_asset: "",
    date_of_sale: "",
    date_of_exercise: "",
    description_of_asset_sold: "",
    _total_sale_price: "",
    transfer_expenses: "",
    total_purchase_price: "",
    check_stt: "",
  });
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const handleFormData = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const submitForm = () => {
    handleSubmit(formData);
  };
  useEffect(() => {
    setActiveTab(strings.income.stocks);
  }, []);
  return (
    <Root theme={theme}>
      <div>
        <StyleFormBox>
        <h4>{strings.income.add_other_assets}</h4>
          <hr />
          
          <div className="flex_input my-2">
            <Label label={strings.income.date_of_sale} />
            <DateField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.date_of_purchase} />
            <DateField error={""} value={""} handleChange={() => {}} />
          </div>
        </StyleFormBox>

        <StyleFormBox>
          <h4>{strings.income.add_details_asset}</h4>
          <hr />
          <div className="flex_input my-2">
            <Label label={strings.income.description_of_asset_sold} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.total_sale_price} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.transfer_expenses} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.total_purchase_price} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
        </StyleFormBox>
       
        <StyleFormBox>
          <div className="flex_input my-2 justify-between">
            <h4>{strings.income.do_you_want_add_cose_improvment_details}</h4>
            <ToggelButton />
          </div>
          <p>{strings.income.expenses_incurred_increasing_value_of_property}</p>

         <StyleFormBox>
         <div className="flex_input my-2">
            <Label label={strings.label.description} />
            <TextArea error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.amount} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="text-right mt-5">
            <AddMoreButton title={strings.button.add_more_item} />
            <AddActionButton
              title={strings.button.add}
              handleChange={() => {}}
            />
          </div>
         </StyleFormBox>
        </StyleFormBox>
        <PrimaryButton
          title={strings.button.submit}
          handleClick={() => {
            submitForm();
          }}
          addClass={"mt-4"}
        />
      </div>
    </Root>
  );
}
const Root = styled.section``;
