import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Label from "../../../../styledComponent/Inputs/Label";
import SelectOption from "../../../../styledComponent/Inputs/SelectOption";
import DateField from "../../../../styledComponent/Inputs/DateField";
import InputField from "../../../../styledComponent/Inputs/InputField";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";
export default function BondsDebentures({ handleSubmit }) {
  const [activeTab, setActiveTab] = useState("");
  const [formData, setFormData] = useState({
    select_type_of_asset: "",
    date_of_sale: "",
    date_of_exercise: "",
    description_of_asset_sold: "",
    _total_sale_price: "",
    transfer_expenses: "",
    total_purchase_price: "",
    check_stt: "",
  });
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const handleFormData = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const submitForm = () => {
    handleSubmit(formData);
  };
  useEffect(() => {
    setActiveTab(strings.income.stocks);
  }, []);
  return (
    <Root theme={theme}>
      <div>
        <StyleFormBox>
        <h4>{strings.income.add_bonds_debentures}</h4>
        <p>{strings.income.specify_those_you_sold_redeemed}</p>
          <hr />
          <div className="flex_input my-2">
            <Label label={strings.income.select_type_bond} required={true} />
            <SelectOption
              options={[strings.income.select_type_bond, strings.income.select_type_bond]}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.date_of_sale} />
            <DateField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.date_of_exercise} />
            <DateField error={""} value={""} handleChange={() => {}} />
          </div>
        </StyleFormBox>

        <StyleFormBox>
          <h4>{strings.income.add_details_asset}</h4>
          <hr />
          <div className="flex_input my-2">
            <Label label={strings.income.description_of_asset_sold} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.sale_value} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.purchase_price} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.transfer_expenses_if_any} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
         
        </StyleFormBox>
        <p>{strings.message.received_any_interest_coupon_payment_in_same_financial_year}</p>
        <PrimaryButton
          title={strings.button.submit}
          handleClick={() => {
            submitForm();
          }}
          addClass={"mt-4"}
        />
      </div>
    </Root>
  );
}
const Root = styled.section``;
