import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Label from "../../../../styledComponent/Inputs/Label";
import SelectOption from "../../../../styledComponent/Inputs/SelectOption";
import DateField from "../../../../styledComponent/Inputs/DateField";
import InputField from "../../../../styledComponent/Inputs/InputField";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import ToggelButton from "../../../../styledComponent/Button/ToggelButton";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";
export default function AddEquityFunds({handleSubmit}) {
  const [activeTab, setActiveTab] = useState("");
  const [formData , setFormData] = useState({
select_type_of_asset:'',date_of_sale:'',date_of_exercise:'',description_of_asset_sold:'',_total_sale_price:'',transfer_expenses:'',total_purchase_price:'',check_stt:''
  })
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const handleFormData = (key,value)=>{
    setFormData({...formData,[key]:value})
  }

  const submitForm = ()=>{
    handleSubmit(formData)
  }
  useEffect(() => {
    setActiveTab(strings.income.stocks);
  }, []);
  return (
    <Root theme={theme}>
      <div className="action_tabs">
        <button
          className={activeTab === strings.income.stocks ? "active" : ""}
          onClick={() => {
            setActiveTab(strings.income.stocks);
          }}
        >
          {strings.income.stocks}
        </button>
        <button
          className={activeTab === strings.income.mutual_funds ? "active" : ""}
          onClick={() => {
            setActiveTab(strings.income.mutual_funds);
          }}
        >
          {strings.income.mutual_funds}
        </button>
      </div>
      <div>
        <StyleFormBox>
          <div className="flex_input my-2">
            <Label label={strings.income.select_asset} />
            <SelectOption
              options={[
                strings.income.select_asset,
                strings.income.select_asset,
              ]}
              error={""}
            //   value={activeTab}
              handleChange={(e) => {
                handleFormData('select_type_of_asset',e)
              }}
            />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.date_of_sale} />
            <DateField
              placeholder={strings.label.date_of_birth}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.date_of_exercise} />
            <DateField
              placeholder={strings.label.date_of_birth}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </StyleFormBox>

        <StyleFormBox>
          <h4>{strings.income.add_details_asset}</h4>
          <hr />
          <div className="flex_input my-2">
            <Label label={strings.income.description_of_asset_sold} />
            <InputField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.total_sale_price} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.transfer_expenses} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2">
            <Label label={strings.income.total_purchase_price} />
            <CurrencyField error={""} value={""} handleChange={() => {}} />
          </div>
          <div className="flex_input my-2 justify-between">
           <h4>{strings.income.did_you_pay_stt}</h4>
           <ToggelButton />
          </div>
        </StyleFormBox>
        <PrimaryButton
                title={strings.button.submit}
                handleClick={() => {
                    submitForm();
                }}
                addClass={"mt-4"}
              />
      </div>
    </Root>
  );
}
const Root = styled.section`
  .action_tabs {
    margin-top: 10px;
    > button {
      padding: 10px;
      width: 50%;
      background-color: ${(p) => p.theme.tertiary_bg};
      border: 1px solid transparent;
      font-weight: 600;
      &:first-child {
        border-radius: 10px 0px 0px 10px;
      }
      &:last-child {
        border-radius: 0px 10px 10px 0px;
      }
      &.active {
        color: ${(p) => p.theme.primary_bg};
        border: 1px solid ${(p) => p.theme.primary_bg};
      }
    }
  }
`;
