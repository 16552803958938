import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {  NavLink,  } from 'react-router-dom'
import styled from 'styled-components'
import ProfileDropDown from './ProfileDropDown'
import SearchBar from './SearchBar'
import LanguageSelect from './LanguageSelect'
import ThemeSelect from './ThemeSelect'
import Notification from '../Notification'
export default function Header() {
    const {strings,theme} = useSelector(s=>s.platformSetting)
  return (
    <HeaderSection theme={theme}>
    <div className='top_bar' style={{backgroundColor: theme.primary_bg}}>
<div className='container_box flex items-center justify-end gap-2'>
<p>Newsletters</p>
<p>Email Us</p>

</div>

    </div>
     <header style={{backgroundColor: theme.secandary_bg}}>
     <div className={`text-white p-4 flex items-center justify-between container_box`}>
     <div className="flex items-center space-x-3">
        <span className="font-bold text-lg"><img src='/images/samosa_logo.png' /></span>
      </div>
      <nav>
        <ul className="flex space-x-4 menu_bar">
          <NavLink to="/" className={({ isActive }) => isActive ? "active" : "" } >Home </NavLink>
          <NavLink to="/" className={({ isActive }) => isActive ? "active" : "" } >Products </NavLink>
          <NavLink to="/" className={({ isActive }) => isActive ? "active" : "" } >Tools </NavLink>
          <NavLink to="/" className={({ isActive }) => isActive ? "active" : "" } >Contact </NavLink>
          <NavLink to="/" className={({ isActive }) => isActive ? "active" : "" } >Login </NavLink>
          <NavLink to="/" className={({ isActive }) => isActive ? "active" : "" } >Sign up </NavLink>

          {/* <NavLink to="/login" className={({ isActive }) => isActive ? "active" : "" } >Login </NavLink>
          <NavLink to="/register" className={({ isActive }) => isActive ? "active" : "" } >Create User </NavLink>
          <NavLink to="/form/ITR1" className={({ isActive }) => isActive ? "active" : "" } >ITR 1 </NavLink>
          <NavLink to="/form/ITR2" className={({ isActive }) => isActive ? "active" : "" } >ITR 2</NavLink>
          <NavLink to="/form/ITR3-4" className={({ isActive }) => isActive ? "active" : "" } >ITR 3-4</NavLink>
          <NavLink to="/firm" className={({ isActive }) => isActive ? "active" : "" } >Firm</NavLink>
          <NavLink to="/huf-form" className={({ isActive }) => isActive ? "active" : "" } >HUF Form</NavLink>
          <NavLink to="/profile" className={({ isActive }) => isActive ? "active" : "" } >Profile</NavLink> */}
        </ul>
      </nav>
      {/* <div className='flex gap-2 items-center'>
        <SearchBar/>
        <ThemeSelect/>
        <Notification/>
        <LanguageSelect />
        <ProfileDropDown/>
      </div> */}</div>
    </header>
    </HeaderSection>
  )
}
const HeaderSection = styled.section`
.top_bar{
  color:${p => p.theme.secandary_txt};
}
.menu_bar{
  color:${p => p.theme.primary_txt};
  .active{
    font-weight:600;
  }
}
`