import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IoMdCall } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
export default function Support() {
    const { strings, theme } = useSelector((s) => s.platformSetting);

  return (
    <Root theme={theme}>
      <div className="container_box">
        <h4 className="text-4xl mb-5">Need support?</h4>
        <div className="flex items-center justify-between flex-wrap">
            <div className="flex items-center gap-2"><IoMdCall /> +91 9116-68-44-39</div>
            <div className="flex items-center gap-2"><HiOutlineMail /> support@samosaTax.in</div>
            <div className="flex items-center gap-2"><label>Set Alert for Tax News</label><input type='text' placeholder='Enter Your Email Address'/> </div>
            <div><button className="subscribe">Subscribe</button></div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  background-color: ${(p) => p.theme.primary_bg};
  color: ${(p) => p.theme.secandary_txt};
  padding:30px;
  div{
    >input{
        background: transparent;
        border: 1px solid ${(p) => p.theme.secandary_txt};
        padding:10px 20px;
        border-radius:50px;
    }
  }
  .subscribe{
    background-color:${(p) => p.theme.secandary_txt};
    color:${(p) => p.theme.primary_bg};
    padding:10px 20px;
    border-radius:50px;
    font-weight:600;
  }
`