import React, { useState } from "react";
import CurrencyField from "../../../styledComponent/Inputs/CurrencyField";
import AccordianBox from "../../../styledComponent/Form/AccordianBox";
import { useSelector } from "react-redux";
import Label from "../../../styledComponent/Inputs/Label";
import { CiMenuKebab } from "react-icons/ci";
import SecandaryButton from "../../../styledComponent/Button/SecandaryButton";
import PrimaryButton from "../../../styledComponent/Button/PrimaryButton";
import styled from "styled-components";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import EmployerTdsDetails from "./EmployerTdsDetails";
import SalaryBreakup from "./SalaryBreakup";
import ExemptAllowances from "./ExemptAllowances";
import OtherSourceIncome from "./OtherSourceIncome";
import FormDialogBox from "../../DialogBox/FormDialogBox";
import HousePropertyIncome from "./HousePropertyIncome";
import ProfessionalIncome from "./AllIncomeType/ProfessionalIncome";
import IncomeTypes from "./AllIncomeType/Index";
export default function GrossIncome({ handleNext }) {
  const [openIncomeForm, setOpenIncomeForm] = useState(false);
  const [professionalIncomeForm, setProfessionalIncomeForm] = useState(false);
  const [houseIncomeForm, setHouseIncomeForm] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root>
      <AccordianBox
        title={strings.tax.salary_income}
        description={strings.tax.you_can_add_salary_income}
      >
        <EmployerTdsDetails />
        <SalaryBreakup />
        <ExemptAllowances />
        <StyleFormBox>
          <div className="flex-1 flex items-center justify-between">
            <h4 style={{ color: theme.primary_txt }}>
              {strings.label.balance} (1 -2)
            </h4>
            <h4 style={{ color: theme.primary_txt }}>
              {strings.label.rupee} 10,000
            </h4>
          </div>
        </StyleFormBox>
        <AccordianBox title={strings.tax.deductions_and_relief}>
          <div className="flex_input">
            <div>
              <Label label={strings.label.standard_deductions_16ia} />
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
            <div>
              <Label
                label={strings.label.professional_tax_under_section_16iii}
              />
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
          </div>

          <div className="flex_input">
            <div>
              <Label label={strings.label.relief_under_section_89} />
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
            <div>
              <Label
                label={strings.label.income_claimed_for_relief_under_sectio_89a}
              />
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
          </div>
        </AccordianBox>
        <StyleFormBox>
          <div className="flex-1 flex items-center justify-between">
            <h4 style={{ color: theme.primary_txt }}>
              {strings.tax.income_chargeable_under_head_salaries} (1 -2)
            </h4>
            <h4 style={{ color: theme.primary_txt }}>
              {strings.label.rupee} 10,000
            </h4>
          </div>
        </StyleFormBox>
      </AccordianBox>

      <AccordianBox
        title={strings.income.professional_freelencing_business_income}
        description={strings.income.for_all_professionals}
      >
        <div className="flex items-center gap-2 my-5">
          <h4 className="mr-auto">{strings.income.professional_income_total}</h4>
          <h4>34,000</h4>
          <button>
            <CiMenuKebab />
          </button>
        </div>
        <SecandaryButton
          title={strings.button.add_entry}
          handleClick={() => {
            setProfessionalIncomeForm(true);
          }}
        />
        {/* <hr /> */}

        <div className="my-5">
          <h4>{strings.income.financial_particulars}</h4>
         <p>{strings.income.mandatory_presumptive_income}</p>
        </div>
        <div className="flex items-center gap-2 my-5">
          <h4 className="mr-auto">{strings.income.professional_income_total} :</h4>
          <h4>34,000</h4>
          <button >
            <CiMenuKebab />
          </button>
        </div>
        <SecandaryButton
          title={strings.button.add_entry}
          handleClick={() => {
            setProfessionalIncomeForm(true);
          }}
        />
      </AccordianBox>

      <AccordianBox
        title={strings.tax.other_source_income}
        description={
          strings.tax.fixed_deposite_savings_bank_interest_agriculture
        }
      >
        <FormDialogBox
          open={openIncomeForm}
          formTitle={strings.button.add_entry}
          handleClose={() => {
            setOpenIncomeForm(false);
          }}
        >
          <OtherSourceIncome />
        </FormDialogBox>

        <SecandaryButton
          title={strings.button.add_entry}
          handleClick={() => {
            setOpenIncomeForm(true);
          }}
        />
      </AccordianBox>

      <AccordianBox
        title={strings.tax.house_property_income}
        description={strings.tax.add_details_earned_rent_from_property}
      >
        <div className="flex justify-end items-center">
          <h4>House Property 1</h4>
          <h2>34,000</h2>
          <button>
            <CiMenuKebab />
          </button>
        </div>
        <FormDialogBox
          open={houseIncomeForm}
          formTitle={strings.button.add_entry}
          handleClose={() => {
            setHouseIncomeForm(false);
          }}
        >
          <HousePropertyIncome />
        </FormDialogBox>
        <SecandaryButton
          title={strings.button.add_entry}
          handleClick={() => {
            setHouseIncomeForm(true);
          }}
        />
      </AccordianBox>




        {/*++++++  Professional, Freelancing and Business Income  ++++++*/}
        <FormDialogBox
          open={professionalIncomeForm}
          formTitle={strings.income.professional_freelencing_business_income}
          handleClose={() => {
            setProfessionalIncomeForm(false);
          }}
        >
          <IncomeTypes />
        </FormDialogBox>
        {/*++++++  Professional, Freelancing and Business Income  ++++++*/}
    </Root>
  );
}
const Root = styled.div`
  .flex_title {
    display: flex;
    justify-content: space-between;
  }
`;
