import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
export default function Label({label,required ,addClass}) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme} className={addClass}>{label} {required && ' *'}</Root>
  )
}
const Root = styled.div`
    display: block;
    font-size:12px;
    color: ${(p) => p.theme.primary_txt};
    font-weight: 500;
    margin:10px 0px;
`