import React, { useEffect, useState } from "react";
import { IoNotificationsSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { addOpacity } from "../../utils/helpers";
import styled from "styled-components";
export default function Notification() {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const data = [
    {
      id: 1,
      name: "Link Your PAN",
      description:
        "PAN Linking is a mandatory requirement to e-file your returns.",
      createdAt: "10:00 PM",
      isActive: true,
      icon: "",
    },
    {
      id: 2,
      name: "Link Your PAN",
      description:
        "PAN Linking is a mandatory requirement to e-file your returns.",
      createdAt: "5:00 PM",
      isActive: true,
      icon: "",
    },
    {
      id: 3,
      name: "Lorem Ipsum is simply dummy",
      description:
        "Lorem Ipsum is simply dummy text of the and typesetting industry. Lorem Ipsum has be the industry's standard dummy text ever since the 1500s",
      createdAt: "1:00 PM",
      isActive: true,
      icon: "",
    },
    {
      id: 4,
      name: "Lorem Ipsum is simply dummy",
      description:
        "Lorem Ipsum is simply dummy text of the and typesetting industry. Lorem Ipsum has be the industry's standard dummy text ever since the 1500s",
      createdAt: "1:00 PM",
      isActive: false,
      icon: "",
    },
  ];

  useEffect(() => {
    setNotifications(data);
  }, []);
  return (
    <Root theme={theme}>
      <button
        onClick={() => setIsOpen(true)}
        className="block p-2 rounded"
        style={{ background: addOpacity(theme.secandary_bg, 0.3) }}
      >
        <IoNotificationsSharp />
      </button>
      {isOpen && (
        <div>
          <div
            className="absolute right-0 top-16 z-20  p-4 h-[92%]"
            style={{ backgroundColor: theme.secandary_bg }}
          >
            <div>
              {notifications.map((item) => {
                return (
                  <div
                    className={
                      item.isActive
                        ? "notification_box active"
                        : "notification_box"
                    }
                  >
                    <span className="img_box">
                      <img src="/images/dummy_icon.png" />
                    </span>
                    <h4>{item.name}</h4>
                    <h5>{item.createdAt}</h5>
                    <p>{item.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <span
            className="fixed inset-0 bg-[#00000045] z-10"
            onClick={() => {
              setIsOpen(false);
            }}
          ></span>
        </div>
      )}
    </Root>
  );
}
const Root = styled.div`
  .notification_box {
    background-color: ${(p) => p.theme.tertiary_bg};
    padding: 8px 4px 4px 16px;
    color: ${(p) => p.theme.primary_txt};
    border-radius: 10px;
    border: 1px solid ${(p) => p.theme.border_clr};
    display: grid;
    grid-template-columns: 30px 1fr 60px;
    column-gap: 6px;
    width: 340px;
    align-items: center;
    cursor: pointer;
    margin: 10px 0px;
    opacity: 0.4;
    &.active {
      opacity: 1;
      > .img_box {
      position: relative;
      &::before {
        content: "";
        background: #2a8ed6;
        width: 6px;
        height: 6px;
        display: block;
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }
    }
    > h4 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > h5 {
      text-align: right;
      font-size: 12px;
      opacity: 0.6;
      font-weight: 400;
    }
    > p {
      grid-column-start: 2;
      grid-column-end: 4;
    }
 
  }
`;
