import React, { useState } from "react";
import AccordianBox from "../../styledComponent/Form/AccordianBox";
import Label from "../../styledComponent/Inputs/Label";
import InputField from "../../styledComponent/Inputs/InputField";
import { useSelector } from "react-redux";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import SelectOption from "../../styledComponent/Inputs/SelectOption";
import AddActionButton from "../../styledComponent/Button/AddActionButton";
import AddBankDetails from "../DialogBox/AddBankDetails";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import styled from "styled-components";
import DateField from "../../styledComponent/Inputs/DateField";
import PhoneField from "../../styledComponent/Inputs/PhoneField";
export default function PersonalInfo({ handleNext }) {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const [addBankBox, setAddBankBox] = useState(false);
  return (
    <Root>
    <h4 className="mb-5" style={{color:theme.primary_txt}}>{strings.tax.assessment_year}</h4>
    <StyleFormBox>
    <SelectOption
            options={[
              strings.tax.assessment_year,
              strings.tax.assessment_year,
            ]}
            error={""}
            value={""}
            handleChange={() => {}}
          />
    </StyleFormBox>
    
      <AccordianBox
        title={strings.tax.permanent_information}
        description={strings.tax.please_provide_all_info_as_per_your_govt_id}
      >
        {/* <div className="flex gap-4 my-2">
          <Label
            label={strings.label.name}
            required={true}
            addClass={"flex-1"}
          />
          <div className="flex gap-4 flex-grow-3">
            <InputField
              placeholder={strings.label.first_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />
            <InputField
              placeholder={strings.label.middle_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />
            <InputField
              placeholder={strings.label.last_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div> */}

        <div className="flex_input">
          <div>
            <Label label={strings.label.first_name} required={true} />
            <InputField
              placeholder={strings.label.first_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.label.middle_name} />
            <InputField
              placeholder={strings.label.middle_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>
        
        <div className="flex_input">
          <div>
            <Label label={strings.label.last_name} required={true} />
            <InputField
              placeholder={strings.label.last_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.label.date_of_birth} />
            <DateField
              placeholder={strings.label.date_of_birth}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>
      
        <div>
          <Label label={strings.label.nature_of_employment} />
          <SelectOption
            options={[
              strings.label.nature_of_employment,
              strings.label.nature_of_employment,
            ]}
            error={""}
            value={""}
            handleChange={() => {}}
          />
        </div>
      </AccordianBox>

      {/* Identification & Contact details  */}
      <AccordianBox
        title={strings.tax.identification_and_contact_Details}
        description={
          strings.tax.to_efile_your_returns_please_provide_your_aadhaar_pan
        }
      >
        <div className="flex_input">
          <div>
            <Label label={strings.label.aadhar_number} required={true} />
            <InputField
              placeholder={strings.placeholder.enter_12_digit}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.label.aadhaar_enrollement_no} />
            <InputField
              placeholder={strings.placeholder.enter_28_digit}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>

        <div className="flex_input">
          <div>
            <Label label={strings.label.pan} required={true} />
            <InputField
              placeholder={strings.placeholder.enter_10_digit}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.label.email} />
            <InputField
              placeholder={strings.placeholder.email_address}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>

        <div className="flex_input">
          <div>
            <Label label={strings.label.phone_number} required={true} />
            <PhoneField
              placeholder={strings.placeholder.enter_10_digit}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
           
          </div>
        </div>

        <h4 className="mt-3">{strings.tax.additional_information}</h4>

        <div className="flex_input">
          <div>
            <Label label={strings.label.secandary_mobile_number} required={true} />
            <PhoneField
              placeholder={strings.placeholder.enter_10_digit}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.label.secandary_email_address} />
            <InputField
              placeholder={strings.placeholder.email_address}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>

        <div className="flex_input">
          <div>
            <Label label={strings.label.landline_number} required={true} />
            <PhoneField
              placeholder={strings.placeholder.enter_10_digit}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
           
          </div>
        </div>
       
      </AccordianBox>

      {/* Form Identification & Contact details */}
      <AccordianBox
        title={strings.tax.your_address}
        description={
          strings.tax.you_can_provide_either_your_current_address_or_permanent_address
        }
      >
        <div className="flex_input">
          <div>
            <Label label={`${strings.address.flat} / ${strings.address.door_no}`} required={true} />
            <InputField
              placeholder={`${strings.address.flat} / ${strings.address.door_no}`}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.address.premise_name} />
            <InputField
              placeholder={strings.address.premise_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>

        <div className="flex_input">
          <div>
            <Label label={`${strings.address.road} / ${strings.address.street}`} required={true} />
            <InputField
              placeholder={`${strings.address.road} / ${strings.address.street}`}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.address.area_locality} />
            <InputField
              placeholder={strings.address.area_locality}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
        </div>

        <div className="flex_input">
          <div>
            <Label label={strings.address.pincode} required={true} />
            <InputField
              placeholder={strings.address.pincode}
              error={""}
              value={""}
              handleChange={() => {}}
            />
          </div>
          <div>
            <Label label={strings.address.city} />
            <SelectOption
            options={[
              strings.address.city,
              strings.address.city,
            ]}
            error={""}
            value={""}
            handleChange={() => {}}
          />
          </div>
        </div>

        <div className="flex_input">
          <div>
            <Label label={strings.address.state} required={true} />
            <SelectOption
            options={[
              strings.address.state,
              strings.address.state,
            ]}
            error={""}
            value={""}
            handleChange={() => {}}
          />
          </div>
          <div>
           
          </div>
        </div>

      </AccordianBox>

      <AccordianBox
        title={strings.tax.pan_details}
        description={
          strings.tax.pan_linking_is_mandatory_requirement
        }
      >
        <div className="flex_input">
          <div>{strings.label.name}</div>
          <div>Rahul Singh</div>
        </div>
        <div className="flex_input">
          <div>{strings.label.date_of_birth}</div>
          <div>27/01/2000</div>
        </div>
        <div className="flex_input">
          <div>{strings.label.pan_number}</div>
          <div>DRPUTB755865GJ</div>
        </div>
        <div className="flex_input">
          <div>{strings.label.father_name}</div>
          <div>Mohan Lal</div>
        </div>


      </AccordianBox>

      {/* Form Link your PAN*/}
      <StyleFormBox>
        <div className="flex-1 flex items-center justify-between">
          <h4 style={{ color: theme.primary_txt }}>
            {strings.tax.add_bank_details}
          </h4>
          <AddActionButton
            title={strings.button.add}
            handleChange={() => {
              setAddBankBox(true);
            }}
          />
        </div>
      </StyleFormBox>
      {/* 
   <div className="secand_box">
   <PrimaryButton title={strings.button.next} handleClick={()=>{handleNext(strings.tax.gross_income)}} addClass={'mt-4'}/>
   </div> */}

      {/* dialog box for adding bank details */}
      <AddBankDetails
        open={addBankBox}
        handleClose={() => {
          setAddBankBox(false);
        }}
        handleCallback={() => {}}
      />
    </Root>
  );
}
const Root = styled.section``;
