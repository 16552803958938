import React, { useState } from "react";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import styled from "styled-components";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { addOpacity } from "../../utils/helpers";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import SecandaryButton from "../../styledComponent/Button/SecandaryButton";
import { redirect, useNavigate } from "react-router-dom";
import TaxSummary from "./TaxSummary";

export default function UploadForm16({ redirect }) {
  const [activeScreen, setActiveScreen] = useState("uploadForm");
  const { theme, strings } = useSelector((s) => s.platformSetting);
  const navigate = useNavigate();

  return (
    <Root theme={theme}>
      {activeScreen === "uploadForm" ? (
        <div className="upload_form">
          <StyleFormBox>
            <div className="upload_cloud text-5xl  p-8 rounded-full mx-auto">
              <FaCloudUploadAlt />
            </div>
            <p className="my-4">
              {strings.form.auto_fetch_details_upload_form_16}
            </p>
            <h4 className="">{strings.form.choose_your_form_16}</h4>
            <p className="my-4">{strings.form.upload_password_protected_pdf}</p>
            <input type="file" />
            <PrimaryButton
              title={strings.button.submit}
              addClass={"mt-5"}
              handleClick={() => {
                setActiveScreen("taxSummary");
              }}
            />
          </StyleFormBox>
          <StyleFormBox>
            <p className="my-4">{strings.form.upload_password_protected_pdf}</p>
            <SecandaryButton
              handleClick={() => {
                navigate(redirect);
              }}
              title={strings.button.continue_without_form_16}
              addClass={"mt-5"}
            />
          </StyleFormBox>
        </div>
      ) : activeScreen === "taxSummary" ? (
        <div className="tax_summary">
        <TaxSummary editItr={()=>{ navigate(redirect)}} />
        </div>
      ) : (
        ""
      )}
    </Root>
  );
}
const Root = styled.div`
  .tax_summary{
    max-width:800px;
    margin:auto;
  }
  .upload_form {
    max-width: 500px;
    margin: auto;
    text-align: center;
  }
  .upload_cloud {
    width: fit-content;
    background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.2)};
  }
`;
