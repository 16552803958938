import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import ProgressBar from "../Form/ProgressBar";
import { useNavigate } from "react-router-dom";
import SelfDeclaration from "../ITR/SelfDeclaration";
import MakePayment from "../ITR/MakePayment";
import EverifyReturn from "../ITR/VerifyReturn";
import PersonalInfo from "../ITR1/PersonalInfo";
import TaxSaving from "../ITR1/TaxSaving";
import TaxSummary from "../ITR1/TaxSummary";
import GrossIncome from "./GrossIncome";

export default function ITR34Form() {
  const [activeSection, setActiveSection] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [activeScreen, setActiveScreen] = useState("itr_form");
  const [stepsDone, setStepsDone] = useState([]);
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const navigate = useNavigate();

  const handleNextClick = () => {
    if (activeTab < 4) {
      setActiveTab(activeTab + 1);
      setStepsDone([...stepsDone, activeTab]);
    } else {
      setActiveScreen("self_declaration");
      // navigate("/self-declaration")
    }
  };

  const steps = [
    {
      id: 1,
      title: "Personal info",
    },
    {
      id: 2,
      title: "Gross Income",
    },
    {
      id: 3,
      title: "Tax Saving",
    },
    {
      id: 4,
      title: "Tax Summary",
    },
  ];

  useEffect(() => {
    if (strings.tax.personal_info) {
      setActiveTab(2);
    }
  }, [strings]);

  return (
    <Root theme={theme} className="container_box">
      {activeScreen === "itr_form" ? (
        <>
          <div className="progress_bar">
            <ProgressBar
              active={activeTab}
              stepDone={stepsDone}
              options={steps}
            />
          </div>
          <div className="main_container">
            <div className="content_form_box">
              {" "}
              {activeTab === 1 ? (
                <PersonalInfo
                  handleNext={() => {
                    handleNextClick();
                  }}
                />
              ) : activeTab === 2 ? (
                <GrossIncome
                  handleNext={() => {
                    handleNextClick();
                  }}
                />
              ) : activeTab === 3 ? (
                <TaxSaving
                  handleNext={() => {
                    handleNextClick();
                  }}
                />
              ) : activeTab === 4 ? (
                <TaxSummary />
              ) : (
                ""
              )}
            </div>
            <div className="action_button">
              <PrimaryButton
                title={strings.button.next}
                handleClick={() => {
                  handleNextClick();
                }}
                addClass={"mt-4"}
              />
            </div>
          </div>
        </>
      ) : activeScreen === "self_declaration" ? (
        <>
          <SelfDeclaration
            handleClick={(e) => {
              setActiveScreen(e);
            }}
          />
        </>
      ) : activeScreen === "make_payment" ? (
        <>
          <MakePayment
            handleClick={(e) => {
              setActiveScreen(e);
            }}
          />
        </>
      ) : activeScreen === "e_verify_return" ? (
        <>
          <EverifyReturn />
        </>
      ) : (
        ""
      )}
    </Root>
  );
}
const Root = styled.section`
  .progress_bar {
    margin: 20px 0px;
  }
  .main_container {
    display: flex;
    gap: 10px;
    .content_form_box {
      flex: 3;
    }
    .action_button {
      flex: 1;
      display: flex;
      align-items: end;
      /* margin-top: 40px; */
    }
  }
`;

