import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { addOpacity } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import { FaUser } from "react-icons/fa";
import Label from '../../styledComponent/Inputs/Label';
import InputField from '../../styledComponent/Inputs/InputField';
import PhoneField from '../../styledComponent/Inputs/PhoneField';
import PasswordField from '../../styledComponent/Inputs/PasswordField';
import PrimaryButton from '../../styledComponent/Button/PrimaryButton';
import DateField from '../../styledComponent/Inputs/DateField';
export default function CreateUser({handleClick}) {
    const [userImage , setUserImage] = useState()
    const { strings, theme } = useSelector((s) => s.platformSetting);

  return (
    <Root theme={theme}>
        <div>
        <div className='image_select w-40 h-40 rounded-full block mx-auto mt-[-40px] border-6 overflow-hidden relative'>
            <input type='file' className='h-full w-full cursor-pointer opacity-0 relative z-20' onChange={(e)=>{setUserImage(e.target.files)}}/>
            {userImage ? <img src={URL.createObjectURL(userImage[0])}  className="absolute top-0 left-0 h-full w-full object-cover"/> : <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-7xl z-10' style={{color:theme.primary_bg}}><FaUser /></span>}
        </div>

        <div className='flex justify-between gap-3' >
            <div className='w-full'>
                <Label label={strings.label.first_name} />
                <InputField
                placeholder={strings.label.first_name}
                error={""}
                value={""}
                handleChange={() => {}}
              />
            </div>
            <div className='w-full'>
                <Label label={strings.label.last_name} />
                <InputField
                placeholder={strings.label.last_name}
                error={""}
                value={""}
                handleChange={() => {}}
              />
            </div>
        </div>
       
        <div className='flex justify-between gap-3' >
            <div className='w-full'>
                <Label label={strings.label.date_of_birth} />
                <DateField
                placeholder={strings.label.date_of_birth}
                error={""}
                value={""}
                handleChange={() => {}}
              />
            </div>
            <div className='w-full'>
                <Label label={strings.label.email} />
                <InputField
                placeholder={strings.label.email}
                error={""}
                value={""}
                handleChange={() => {}}
              />
            </div>
        </div>

        <div className='flex justify-between gap-3' >
            <div className='w-full'>
                <Label label={strings.label.phone_number} />
                <PhoneField
                placeholder={strings.label.phone_number}
                error={""}
                value={""}
                handleChange={() => {}}
              />
            </div>
            <div className='w-full'>
                <Label label={strings.label.password} />
                <PasswordField
                placeholder={strings.label.password}
                error={""}
                value={""}
                handleChange={() => {}}
              />
            </div>
        </div>

        <div className='flex justify-between gap-3' >
            <div className='w-full'>
                <Label label={strings.label.confirm_password} />
                <PasswordField
                placeholder={strings.label.confirm_password}
                error={""}
                value={""}
                handleChange={() => {}}
              />
            </div>
            <div className='w-full'>
               
            </div>
        </div>
        </div>

        <PrimaryButton title={strings.button.save_and_continue} handleClick={()=>{handleClick()}} addClass={'mt-5'}/>

       
    </Root>
  )
}
const Root = styled.section`
  background-color: ${(p) => p.theme.secandary_bg};
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
.image_select{
    background-color: ${(p) => p.theme.secandary_bg};
    border:8px solid ${p=> p.theme.tertiary_bg}
}
`