
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from '../../utils/helpers';
export default function SelectOption({ options,error ,value,handleChange}) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
      <select value={value} onChange={(e)=>{handleChange(e.currentTarget.value)}}> {options.map((i,ix)=><option value={i} key={ix}>{i}</option>)}</select>
      {/* <p className="error">{error}</p> */}
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  input , select{
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    font-size: 12px;
    border: 1px solid ${(p) =>p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background: ${(p) => p.theme.secandary_bg};
    /* background:transparent; */
    &::placeholder {
      color: ${(p) => p.theme.primary_txt};
      font-weight: 400;
      font-size: 14px;
    }
  }
  .error{
    font-size: 12px;
    color: red;
    left: 16px;
    position: absolute;
    bottom: -2px;
  }
`;
