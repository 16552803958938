import React, { useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useSelector } from "react-redux";
import AddActionButton from "../../../../styledComponent/Button/AddActionButton";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import { FaRegEdit } from "react-icons/fa";
import AddBusinessData from "./AddBusinessData";
import { RiDeleteBin6Fill } from "react-icons/ri";
import styled from "styled-components";
import AddGoodsCarriage from "./AddGoodsCarriage";
export default function GoodsCarriagesIncome() {
  const [businessBox , setBusinessBox] = useState(false)
  const [goodsCarriageBox , setGoodsCarriageBox] = useState(false)
  const [businessData , setBusinessData] = useState({})
  const { strings, theme } = useSelector((s) => s.platformSetting);
  console.log('businessData',businessData)
  return (
    <Root theme={theme}>
      <StyleFormBox>
        <div className="flex_input  justify-between">
          <h4>{strings.income.business_details}</h4>
          <AddActionButton title={strings.button.add} handleChange={()=>{setBusinessBox(true)}}/>
        </div>
        <hr />
        <div className="mt-4">
          <div className="flex items-center justify-between">
            <h4>Bytes Bee</h4>
            <h5>01002</h5>
          </div>
          <div className="flex items-center justify-between">
            <p>Has all you can eat · Serves happy hour food · Serves vegetarian dishes</p>
            <div className="action_buttons"><button className="edit_btn"><FaRegEdit /></button><button className="delete_btn"><RiDeleteBin6Fill /></button></div>
          </div>
        </div>
      </StyleFormBox>
      <StyleFormBox>
        <div className="flex_input  justify-between">
          <h4>{strings.income.goods_carriage_details}</h4>
          <AddActionButton title={strings.button.add} handleChange={()=>{setGoodsCarriageBox(true)}}/>
        </div>
        <hr />
        <div className="mt-4">
          <div className="flex items-center justify-between">
            <h4>bdscdfg3e32521</h4>
            <h5>Owned</h5>
          </div>
          <div className="flex items-center justify-between">
            <p>Income : 20,000 <br/> Month : 23 , Number of Tons : 12</p>
            <div className="action_buttons"><button className="edit_btn"><FaRegEdit /></button><button className="delete_btn"><RiDeleteBin6Fill /></button></div>
          </div>
        </div>
      </StyleFormBox>

      <FormDialogBox
        open={businessBox}
        formTitle={strings.income.add_business_details}
        handleClose={() => {
          setBusinessBox(false);
        }}
      >
        <AddBusinessData
          handleSubmit={(data) => {
            console.log('data--',data)
            setBusinessData({...businessData , ...data})
            setBusinessBox(false);
          }}
        />
      </FormDialogBox>

      <FormDialogBox
        open={goodsCarriageBox}
        formTitle={strings.income.add_goods_carriage_details}
        handleClose={() => {
          setGoodsCarriageBox(false);
        }}
      >
        <AddGoodsCarriage
          handleSubmit={(data) => {
            console.log('data--',data)
            setBusinessData({...businessData , ...data})
            setGoodsCarriageBox(false);
          }}
        />
      </FormDialogBox>

    </Root>
  );
}
const Root = styled.section`
.action_buttons{
  display:flex;
  font-size: 22px;
  gap: 6px;
  button:first-child{
    padding-right:8px;
    border-right:1px solid ${p => p.theme.border_clr};
  }
}

`