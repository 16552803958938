import { UPDATE_ALL_DATA } from "../constant";

  const initialState = {
    userDetails :'Test user details',
  };
  
  export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_ALL_DATA:
        return {
          ...state,
          userDetails: action.payload,
        };
      default:
        return state;
    }
  };
  