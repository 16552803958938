import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Label from "../styledComponent/Inputs/Label";
import InputField from "../styledComponent/Inputs/InputField";
import PhoneField from "../styledComponent/Inputs/PhoneField";
import TextArea from "../styledComponent/Inputs/TextArea";
import PrimaryButton from '../styledComponent/Button/PrimaryButton'
export default function ContactUs() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <h4>{strings.profile.contact_us}</h4>
      <div className="flex justify-between gap-3">
        <div className="w-full">
          <Label label={strings.label.first_name} />
          <InputField
            placeholder={strings.label.first_name}
            error={""}
            value={""}
            handleChange={() => {}}
          />
        </div>
        <div className="w-full">
          <Label label={strings.label.last_name} />
          <InputField
            placeholder={strings.label.last_name}
            error={""}
            value={""}
            handleChange={() => {}}
          />
        </div>
      </div>

      <div className="flex justify-between gap-3">
        <div className="w-full">
          <Label label={strings.label.email} />
          <InputField
            placeholder={strings.label.email}
            error={""}
            value={""}
            handleChange={() => {}}
          />
        </div>
        <div className="w-full">
          <Label label={strings.label.phone_number} />
          <PhoneField
            placeholder={strings.label.phone_number}
            error={""}
            value={""}
            handleChange={() => {}}
          />
        </div>
      </div>
      <div className="w-full">
          <Label label={strings.label.your_message} />
          <TextArea placeholder={strings.placeholder.type_your_text}
            error={""}
            value={""}
            handleChange={() => {}}/>
        </div>

        <PrimaryButton title={strings.button.submit} handleClick={()=>{ }} addClass={'mt-5'}/>
    </Root>
  );
}
const Root = styled.section`
  background-color: ${(p) => p.theme.secandary_bg};
  padding: 20px;
  margin: 30px auto 0px;
  border-radius: 10px;
  max-width: 800px;
  .image_select {
    background-color: ${(p) => p.theme.secandary_bg};
    border: 8px solid ${(p) => p.theme.tertiary_bg};
  }
`;
